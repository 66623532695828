<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row-equal-cols">
                        <div class="col-lg-6 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">{{title}}</h1>
                                <!-- <button class="btn btn-xs btn-default middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button> -->
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li><a [routerLink]="['/crm-campanas']">Campañas</a></li>
                                <li>{{title}}</li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-botones text-center">
                          <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                          <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                          <button type="button" class="btn btn-info btn-enviar-campana" style="margin-left:30px" (click)="EnviarCampana()" *ngIf="data.id && data.id != ''">Enviar campaña <i class="fa fa-send"></i></button>
                        </div>
                    </div>
                    <div class="row-equal-cols" *ngIf="data.id && data.id != '' && data.tipo == 'whatsapp' && config.cuentas_whatsapp && config.cuentas_whatsapp.length">
                        <div class="col-lg-6 col-md-6 col-xs-12">
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-botones text-center">
                            <select class="form-control" style="width: auto;" [(ngModel)]="id_cuenta_whatsapp">
                                <option [value]="item.id" *ngFor="let item of this.config.cuentas_whatsapp">{{item.nombre}} - <span>{{item.whatsapp_fbid}}</span></option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="block full break-padding">
                    <div class="block-title"></div>
                    
                    
                    <div class="row">
                        <div class="col-md-8 col-xs-12">
                        <div class="form-group">
                            <label>Título <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" [(ngModel)]="data.titulo" maxlength="100">
                        </div>
                        </div>
                        <div class="col-md-4 col-xs-12">
                        <div class="form-group">
                            <label>Tipo <span class="text-danger">*</span></label>
                            <select class="form-control" [(ngModel)]="data.tipo" (change)="SetTipoCampana()">
                                <option value="email" selected>Email</option>
                                <option value="whatsapp" selected>WhatsApp</option>
                            </select>
                        </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8 col-xs-12">
                        <div class="form-group">
                            <label>Asunto <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" [(ngModel)]="data.asunto" maxlength="100">
                        </div>
                        </div>
                        <div class="col-md-4 col-xs-12">
                        <div class="form-group">
                            <label>Plantilla <span class="text-danger">*</span></label>
                            <select class="form-control" [(ngModel)]="data.id_plantilla" (change)="ChangePlantilla()">
                                <option [value]="item.id" *ngFor="let item of plantillas">{{item.asunto}}</option>
                            </select>
                        </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8 col-xs-12">
                            <div class="form-group">
                                <label>Contactos <small class="m-l-30" style="font-weight:normal;">Separados por coma (,)</small> <span class="m-l-30" *ngIf="GetTotalContactos() > 0">Total: {{GetTotalContactos()}}</span></label>
                                <textarea class="form-control" rows="6" [(ngModel)]="data.contactos" (keyup)="LimpiarContactos()"></textarea>
                            </div>
                            <div class="form-group">
                                <a class="btn btn-primary" (click)="AddContactos()"><i class="fa fa-plus"></i> Añadir contactos</a>
                                <a class="btn btn-default m-l-10" (click)="DelContactos()" *ngIf="data.contactos && data.contactos != ''">Borrar contactos</a>
                                <ng-container *ngIf="data.logs && data.logs != ''">
                                    <a class="btn btn-default m-l-20" data-toggle="collapse" href="#logs-campana">Ver logs último envío</a>
                                    <div class="collapse" id="logs-campana">
                                        <p class="logs">{{data.logs}}</p>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="form-group" *ngIf="data.logs_envio && data.logs_envio.length">
                                <ul class="list-group">
                                    <li class="list-group-item active flex" style="align-items:center;">Enviado: 
                                        <a (click)="CargarLogEnvios()" class="btn btn-default btn-xs m-l-15"><i class="fa fa-refresh"></i></a>
                                        
                                        <div *ngIf="data.tipo == 'email'" class="m-t-10 m-l-auto">
                                            <div class="pie-chart" [attr.data-percent]="data.porcent_leido" 
                                            data-size="40" data-line-width="2" data-bar-color="#000312" data-track-color="#ebeef2">
                                            <span class="font-bold font-12" style="color: #000312;margin-top:-8px;display:block;">{{data.porcent_leido}}%</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="list-group-item" *ngFor="let item of data.logs_envio">
                                        <span class="font-italic m-r-20">{{item.fecha}}</span> <a href="mailto:{{item.destinatario}}">{{item.destinatario}}</a>
                                        <i class="fa fa-eye m-l-20" *ngIf="item.leido"></i>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-4 col-xs-12">
                            <div class="form-group">
                                <label>Previsalización del mensaje</label>
                                <p class="previsualizacion-whatsapp breakword" [innerHTML]="data.whatsapp_preview" *ngIf="data.whatsapp_preview"></p>
                                <p class="previsualizacion-email" [innerHTML]="data.email_preview" *ngIf="data.email_preview"></p>
                            </div>
                        </div>
                    </div>
                    
                </div>
    
                <div class="col-botones">
                  <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                  <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                  <ng-container *ngIf="data.id && data.id != '' && data.tipo == 'whatsapp' && config.cuentas_whatsapp && config.cuentas_whatsapp.length">
                    <select class="form-control m-l-50" style="width: auto;" [(ngModel)]="id_cuenta_whatsapp">
                        <option [value]="item.id" *ngFor="let item of this.config.cuentas_whatsapp">{{item.nombre}} - <span>{{item.whatsapp_fbid}}</span></option>
                    </select>
                  </ng-container>
                  <button type="button" class="btn btn-info btn-enviar-campana" style="margin-left:30px" (click)="EnviarCampana()" *ngIf="data.id && data.id != ''">Enviar campaña <i class="fa fa-send"></i></button>
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>

<div class="modal inmodal fade" id="modal-seleccion-clientes" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog modal-lg">
      <div class="modal-content">
      <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
          <h4 class="modal-title">Seleccionar contactos</h4>
      </div>
      <div class="modal-body">

        <div class="row m-b-10">
            <div class="col-sm-6 col-xs-12">
              <label class="inline-block">Lista</label>
              <select class="form-control inline-block m-l-10" style="width:auto;min-width:220px" [(ngModel)]="params.tipo" (change)="Buscar()">
                <option value="">Todas las listas</option>
                <option [value]="item.tipo" *ngFor="let item of listas">{{item.tipo}}</option>
              </select>

            </div>
            <div class="col-sm-6 col-xs-12 text-right">
                <div class="input-group width-100">
                    <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                    <div class="input-group-append cursor" (click)="Buscar()">
                      <span class="input-group-addon"><i class="fa fa-search"></i></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-responsive table-grid block full break-padding">
                    
            <table class="table table-striped table-bordered table-vcenter table-hover">
                <thead>
                    <tr>
                        <th style="width:50px;" class="text-center"><label class="csscheckbox csscheckbox-primary"><input type="checkbox"><span></span></label></th>
                        <th order="asunto">Cliente</th>
                        <th style="width:130px" order="alta">Alta</th>
                        <th style="width:130px" order="tipo">Tipo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of clientes" class="cursor" (click)="SeleccionarContacto($event)">
                        <td class="text-center"><label class="csscheckbox csscheckbox-primary"><input type="checkbox" [attr.data-id]="item.id"><span></span></label></td>
                        <td>
                            <div class="font-bold text-primary" *ngIf="item.nombre">{{item.nombre}} {{item.apellidos}}</div>
                            <div *ngIf="item.email"><i class="fa fa-envelope-o"></i> {{item.email}}</div>
                            <div *ngIf="item.telefono"><i class="fa fa-phone"></i> {{item.telefono}}</div>
                        </td>
                        <td>{{item.alta_format}}</td>
                        <td>{{item.tipo}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="dataTables_paginate paging_simple_numbers m-r-10" *ngIf="params.pages > 1">
              <ul class="pagination justify-content-end">
                <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}">
                  <a (click)="Paging('prev')" class="page-link">Anterior</a>
                </li>
                <li class="paginate_button page-item" *ngIf="params.page_index > 1">
                  <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
                </li>
                <li class="paginate_button page-item active">
                  <a class="page-link cursor">{{params.page_index}}</a>
                </li>
                <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
                  <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
                </li>
                <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}">
                  <a (click)="Paging('next')" class="page-link">Siguiente</a>
                </li>
              </ul>
            </div>
            
        </div>

      </div>
      <div class="modal-footer flex">
        <button id="btn-importar-todo" type="button" class="btn btn-primary" style="margin-right: auto;" (click)="ImportarTodos()">
            <span *ngIf="!params.total">Importar todos los resultados</span>
            <span *ngIf="params.total">Importar <b>{{params.total}}</b> resultados</span>
        </button>
        <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
        <button id="btn-seleccionar-contactos" type="button" class="btn btn-primary" style="margin-left: 15px;" (click)="ReemplazarContactos()" *ngIf="data.puedeseleccionar">Reemplazar existentes</button>
        <button id="btn-seleccionar-contactos" type="button" class="btn btn-primary" style="margin-left: 15px;" (click)="AnadirContactos()" *ngIf="data.puedeseleccionar"><i class="fa fa-plus"></i> Añadir</button>
      </div>
      </div>
  </div>
</div>