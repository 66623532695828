import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let XLSX: any;
declare let saveAs: any;
declare let ace: any;
declare let tinymce: any;

@Component({
  selector: 'app-config-app',
  templateUrl: './config-app.component.html'
})
export class ConfigAppComponent implements OnInit {
  public data:any = {
    customcss: '',
    emailconf_host: '',
    emailconf_port: '',
    emailconf_ssl: 0,
    emailconf_email: '',
    emailconf_pass: '',
    emailconf_alias: '',
    emailconf_emailadmin_alias: '',
    emailconf_emailadmin1: '',
    emailconf_emailadmin2: '',
    emailconf_emailadmin3: '',
  };
  public registro_ips:any = [];
  public params_registro_ips:any = {
    page_index: 1,
    page_size: 50,
    total: 0,
    pages: 0,
    filtro: '',
    order: '',
    dir: ''
  };

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(1);
    $.checkrolredirect(2);
    $.checkrolredirect(3);
    $.checkrolredirect(4);
    this.data = this.globals.config;
    // if (this.data.customcss) this.data.customcss = this.data.customcss.replace(/(?:\r\n|\r|\n)/g, '<br>');
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.2)',
      imageResizeFactor: 0.5
    });
    this.globals.Api('/configuracion').subscribe(data => {
      $.LoadingOverlay("hide");
      if (!data || data.error) return;
      this.globals.config = data;
      this.data = this.globals.config;
      // if (this.data.customcss) this.data.customcss = this.data.customcss.replace(/(?:\r\n|\r|\n)/g, '<br>');
      this.appref.tick();
      // $.csseditor = ace.edit("txtcustomcss");
      // $.csseditor.setTheme("ace/theme/monokai");
      // $.csseditor.session.setMode("ace/mode/css");
    }, error => {
      // toastr.error(`No se ha podido cargar datos de configuración por falta de conexión`, 'Error!');
      $.LoadingOverlay("hide");
      // $.csseditor = ace.edit("txtcustomcss");
      // $.csseditor.setTheme("ace/theme/monokai");
      // $.csseditor.session.setMode("ace/mode/css");
    });
    if (!this.data.emailconf_ssl) this.data.emailconf_ssl = 0;
    // $.logseditor = ace.edit("txtlogsdb");
    // $.logseditor.setTheme("ace/theme/monokai");
    this.CargarLogs();
    this.CargarRegistroIPs();
  }
  
  Guardar() {
    $.buttonloading('.btn-guardar', 'Enviando');
    this.globals.Api('/guardar-configuracion', this.data).subscribe(data => {
      $.buttoncancelloading('.btn-guardar');
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      swal('Guardado', 'Datos guardados correctamente', 'success');
      this.globals.config = this.data;
      localStorage.setItem('config', JSON.stringify(this.globals.config));
      if ($.CargarConfiguracionGlobal) $.CargarConfiguracionGlobal();
    }, error => {
      $.buttoncancelloading('.btn-guardar');
    });
  }

  EmailTest() {
    this.globals.Api('/email-test').subscribe(data => {
      swal('Enviado', 'Intento de email enviado al servidor', 'info');
    }, error =>  {
      swal('Error', 'Se ha producido un error', 'error');
    });
  }
  ExportarDB() {
    this.globals.Api('/exportar-db', {}).subscribe(data => {
      if (!data || !data.content || data.content == '') return;
      var downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute("href",     'data:text/plain;charset=utf-8,'+data.content);
      downloadAnchorNode.setAttribute("download", "database.sql");
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  }
  CargarLogs() {
    this.globals.Api('/logs-db').subscribe(data => {
      if (!data || !data.content || data.content == '') return;
      this.data.logs_db = data.content;
    });
    this.globals.Api('/logs').subscribe(data => {
      if (!data || !data.length) return;
      this.data.logs = data;
    });
    this.globals.Api('/crm-logs').subscribe(data => {
      if (!data || !data.length) return;
      this.data.crm_logs = data;
    });
  }
  BorrarLogs() {
    this.globals.Api('/borrar-logs-db').subscribe(data => {
      this.data.logs_db = '';
    });
  }
  CargarRegistroIPs(paginationload:boolean = true) {
    this.globals.Api('/registro-ips', this.params_registro_ips).subscribe(data => {
      if (!data || !data.data) {
        this.registro_ips = [];
        return;
      }
      this.registro_ips = data.data;
      this.appref.tick();

      if (!paginationload) return;
      this.params_registro_ips.total = data.total;
      this.params_registro_ips.pages = Math.ceil(data.total / data.data.length);
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') {
      this.params_registro_ips.page_index -= 1;
    }
    if (dir == 'next') {
      this.params_registro_ips.page_index += 1;
    }
    this.CargarRegistroIPs(false);
  }
  Buscar() {
    this.params_registro_ips.page_index = 1;
    this.CargarRegistroIPs();
  }

}
