import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let $: any;
declare let swal: any;

@Component({
  selector: 'app-backend-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public globals: Globals
    ) {
  }

  ngOnInit(): void {
  }
  Logout() {
    if ($.Logout) $.Logout();
  }

}
