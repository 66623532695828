<div id="page-wrapper" class="page-loading">
    <!-- Preloader -->
    <!-- Preloader functionality (initialized in js/app.js) - pageLoading() -->
    <!-- Used only if page preloader enabled from inc/config (PHP version) or the class 'page-loading' is added in #page-wrapper element (HTML version) -->
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <!-- END Preloader -->

    <!-- Page Container -->
    <!-- In the PHP version you can set the following options from inc/config file -->
    <!--
        Available #page-container classes:

        'sidebar-light'                                 for a light main sidebar (You can add it along with any other class)

        'sidebar-visible-lg-mini'                       main sidebar condensed - Mini Navigation (> 991px)
        'sidebar-visible-lg-full'                       main sidebar full - Full Navigation (> 991px)

        'sidebar-alt-visible-lg'                        alternative sidebar visible by default (> 991px) (You can add it along with any other class)

        'header-fixed-top'                              has to be added only if the class 'navbar-fixed-top' was added on header.navbar
        'header-fixed-bottom'                           has to be added only if the class 'navbar-fixed-bottom' was added on header.navbar

        'fixed-width'                                   for a fixed width layout (can only be used with a static header/main sidebar layout)

        'enable-cookies'                                enables cookies for remembering active color theme when changed from the sidebar links (You can add it along with any other class)
    -->
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <!-- First Row -->
                <ng-container *ngIf="globals.me && (globals.me.id_rol == 0 || globals.me.id_rol == 1 || globals.me.id_rol == 2)">
                    <div class="row">
                        <!-- Simple Stats Widgets -->
                        <div class="col-sm-6 col-lg-3">
                            <a [routerLink]="['/usuarios']" class="widget">
                                <div class="widget-content widget-content-mini text-right clearfix">
                                    <div class="widget-icon pull-left themed-background-success">
                                        <i class="icofont-people text-light-op"></i>
                                    </div>
                                    <h2 class="widget-heading h3 text-success">
                                        <strong>&nbsp;<span data-toggle="counter" data-to="2862"></span></strong>
                                    </h2>
                                    <span class="text-muted">Usuarios</span>
                                </div>
                            </a>
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <a [routerLink]="['/usuarios/add']" class="widget">
                                <div class="widget-content widget-content-mini text-right clearfix">
                                    <div class="widget-icon pull-left themed-background">
                                        <i class="icofont-ui-user text-light-op"></i>
                                    </div>
                                    <h2 class="widget-heading h3">
                                        <strong>+ <span data-toggle="counter" data-to="2862"></span></strong>
                                    </h2>
                                    <span class="text-muted">Nuevo usuario</span>
                                </div>
                            </a>
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <a [routerLink]="['/licencias']" class="widget">
                                <div class="widget-content widget-content-mini text-right clearfix">
                                    <div class="widget-icon pull-left themed-background-warning">
                                        <i class="icofont-key text-light-op"></i>
                                    </div>
                                    <h2 class="widget-heading h3 text-warning">
                                        <strong>&nbsp;<span data-toggle="counter" data-to="75"></span></strong>
                                    </h2>
                                    <span class="text-muted">Licencias</span>
                                </div>
                            </a>
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <a [routerLink]="['/licencias/add']" class="widget">
                                <div class="widget-content widget-content-mini text-right clearfix">
                                    <div class="widget-icon pull-left themed-background-warning">
                                        <i class="icofont-key text-light-op"></i>
                                    </div>
                                    <h2 class="widget-heading h3">
                                        <strong>+ <span data-toggle="counter" data-to="2862"></span></strong>
                                    </h2>
                                    <span class="text-muted">Nueva licencia</span>
                                </div>
                            </a>
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <a [routerLink]="['/email-builder']" class="widget">
                                <div class="widget-content widget-content-mini text-right clearfix">
                                    <div class="widget-icon pull-left themed-background">
                                        <i class="icofont-envelope text-light-op"></i>
                                    </div>
                                    <h2 class="widget-heading h3 text-success">
                                        <strong>&nbsp;<span data-toggle="counter" data-to="2862"></span></strong>
                                    </h2>
                                    <span class="text-muted">Generador Email</span>
                                </div>
                            </a>
                        </div>
                        <!-- END Simple Stats Widgets -->
                    </div>
                </ng-container>
                <ng-container *ngIf="globals.me && (globals.me.id_rol == 3)">
                    <div class="row">
                        <!-- Simple Stats Widgets -->
                        <div class="col-sm-6 col-lg-3">
                            <a [routerLink]="['/crm-mensajes']" class="widget">
                                <div class="widget-content widget-content-mini text-right clearfix">
                                    <div class="widget-icon pull-left themed-background-success">
                                        <i class="fa fa-whatsapp text-light-op"></i>
                                    </div>
                                    <h2 class="widget-heading h3 text-success">
                                    </h2>
                                    <span class="text-muted">Mensajes</span>
                                </div>
                            </a>
                        </div>
                        <!-- END Simple Stats Widgets -->
                    </div>
                </ng-container>
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>