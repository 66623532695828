import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;

@Component({
  selector: 'app-licencias',
  templateUrl: './licencias.component.html'
})
export class LicenciasComponent implements OnInit {
  public licencias = [];
  public params:any = {
    page_index: 1,
    page_size: 50,
    total: 0,
    pages: 0,
    filtro: '',
    order: '',
    dir: '',
    tipofiltro: null,
    tipofiltrofecha: 'alta'
  };
  public data:any = {};

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public route: ActivatedRoute,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    this.CargarGrid();
    this.globals.GridOrderJS((order, dir) => {
      this.params.order = order;
      this.params.dir = dir;
      this.params.page_index = 1;
      this.CargarGrid();
      this.appref.tick();
    });
    $('thead input:checkbox').click(function() {
      var checkedStatus   = $(this).prop('checked');
      var table           = $(this).closest('table');
      $('tbody input:checkbox', table).each(function() {
          $(this).prop('checked', checkedStatus);
      });
    });
    if (this.route.routeConfig.path == 'licencias/add') {
      this.Nuevo();
    }
    $('.date').datepicker({
      todayBtn: "linked",
      keyboardNavigation: false,
      forceParse: false,
      autoclose: true,
      weekStart: 1,
      language: 'es',
      dateFormat: 'dd/mm/yy'
    });
    $('#txtclave').on('click', () => {
      let textToBeCopied = $('#txtclave')[0];
      textToBeCopied.select();
      textToBeCopied.setSelectionRange(0, 99999);
      document.execCommand('copy');
      $('#txtclavemsg').html('Clave copiada al portapapeles');
      setTimeout(() => {$('#txtclavemsg').html('');}, 3000);
    });
  }

  CargarGrid(paginationload:boolean = true) {
    if (this.globals.GetData()) this.licencias = this.globals.GetData();
    this.globals.Api('/licencias', this.params).subscribe(data => {
      if (!data || !data.data) {
        this.licencias = [];return;
      }
      this.globals.SaveData(data.data);
      for (let item of data.data) {
        if (item.dominio) {
          item.enlace = item.dominio.toString().trim();
          if (item.enlace.toString().indexOf('http') == -1) {
            item.enlace = 'https://' + item.enlace;
          }
        }
      }
      this.licencias = data.data;
      this.appref.tick();

      if (!paginationload) return;
      this.params.total = data.total;
      this.params.pages = Math.ceil(data.total / data.data.length);
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') {
      this.params.page_index -= 1;
    }
    if (dir == 'next') {
      this.params.page_index += 1;
    }
    this.CargarGrid(false);
  }
  Buscar() {
    this.params.page_index = 1;
    this.CargarGrid();
  }
  Filtrar(tipo:string = 'todo', value:string = '') {
    switch(tipo) {
      case 'todo': {
        delete this.params.expiradas;
        delete this.params.expiran_mes;
        this.Buscar();
      } break;
      case 'expiradas': {
        this.params.expiradas = true;
        delete this.params.expiran_mes;
        this.Buscar();
      } break;
      case 'expiran_mes': {
        delete this.params.expiradas;
        this.params.expiran_mes = true;
        this.Buscar();
      } break;
    };
  }
  Nuevo() {
    $('#modal-crear-licencia').modal('show');
    Object.keys(this.data).forEach(item => {this.data[item] = ''});
    this.data.clave = this.globals.GenerateRandomString();
    this.data.clave = this.data.clave.toString().toUpperCase();
    this.data.couta_tipo = 'año';
    setTimeout(() => {
      $('#modal-crear-licencia input[type="text"]')[0].focus();
    }, 500);
  }
  Editar(item:any) {
    this.data = JSON.parse(JSON.stringify(item));
    $('#modal-crear-licencia').modal('show');
    setTimeout(() => {
      $('#modal-crear-licencia input[type="text"]')[0].focus();
    }, 500);
    if (this.data.fecha_expiracion) $('#fechaexpiracion').datepicker('setDate', new Date(this.data.fecha_expiracion));
  }
  GuardarModal() {
    if (
      this.data.clave == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if ($('#fechaexpiracion input').val() == '') this.data.fecha_expiracion = null;
    else this.data.fecha_expiracion = moment($('#fechaexpiracion').datepicker('getDate')).format('YYYY-MM-DD');

    $.buttonloading('btnGuardarModal', 'Enviando');
    if (!this.data.id || this.data.id == '') {
      this.globals.Api('/crear-licencia', this.data).subscribe(data => {
        $.buttoncancelloading('btnGuardarModal');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-crear-licencia').modal('hide');
        this.CargarGrid();
        if (this.route.routeConfig.path == 'licencias/add') {
          this.ngZone.run(() => this.router.navigateByUrl('/licencias')).then();
        }
      }, error => {
        $.buttoncancelloading('btnGuardarModal');
      });
    } else {
      this.globals.Api('/editar-licencia', this.data).subscribe(data => {
        $.buttoncancelloading('btnGuardarModal');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-crear-licencia').modal('hide');
        this.CargarGrid();
      }, error => {
        $.buttoncancelloading('btnGuardarModal');
      });
    }
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar licencia?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/borrar-licencia', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.licencias.splice(this.licencias.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }

}
