import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let toastr: any;

@Component({
  selector: 'app-crm-campanas',
  templateUrl: './campanas.component.html'
})
export class CRMCampanasComponent implements OnInit {
  public data:any = [];
  public params:any = {
    page_index: 1,
    page_size: 50
  };
  public plantillas:any = [];
  public dataitem:any = {};

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(1);
    $.checkrolredirect(2);
    $.checkrolredirect(3);
    $.checkrolredirect(4);
    this.CargarGrid();
    this.globals.GridOrderJS((order, dir) => {
      this.params.order = order;
      this.params.dir = dir;
      this.params.page_index = 1;
      this.CargarGrid();
      this.appref.tick();
    });
    this.globals.Api('/crm-plantillas').subscribe((data:any) => {
      if (!data || !data.length) return;
      this.plantillas = data;
      $.plantillastemp = this.plantillas;
    });
  }

  CargarGrid(paginationload:boolean = true) {
    if (this.globals.GetData()) this.data = this.globals.GetData();
    this.globals.Api('/crm-campanas', this.params).subscribe((data:any) => {
      if (!data || !data.length) {
        this.data = [];
        this.globals.SaveData([]);
        return;
      }
      this.globals.SaveData(data);
      this.data = data;
      this.appref.tick();
      
      $('.pie-chart').easyPieChart({
        barColor: $(this).data('bar-color') ? $(this).data('bar-color') : '#777777',
        trackColor: $(this).data('track-color') ? $(this).data('track-color') : '#eeeeee',
        lineWidth: $(this).data('line-width') ? $(this).data('line-width') : 3,
        size: $(this).data('size') ? $(this).data('size') : '80',
        animate: 800,
        scaleColor: false
      });
      
      if (!paginationload) return;
      this.globals.Api('/crm-campanas/total', this.params).subscribe((data:any) => {
        if (!data || !data.length) return;
        this.params.total = data[0].total;
        this.params.pages = Math.ceil(this.params.total / this.params.page_size);
      });
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') this.params.page_index -= 1;
    if (dir == 'next') this.params.page_index += 1;
    this.CargarGrid(false);
  }
  Buscar() {
    this.params.page_index = 1;
    this.CargarGrid();
  }
  Nuevo() {
    // Object.keys(this.dataitem).forEach(item => {this.dataitem[item] = ''});
    // this.dataitem.title = 'Nueva campaña';
    // this.dataitem.tipo = 'email';
    // this.SetTipoCampana();
    // $('#modal-campana').modal('show');
    // $('.collapse').collapse('hide');
    // setTimeout(() => {
    //   $('#modal-campana input[type="text"]')[0].focus();
    // }, 500);
    this.globals.passData = '';
    this.ngZone.run(() => this.router.navigateByUrl('/crm-campanas/add')).then();
  }
  Editar(item:any) {
    // this.dataitem = JSON.parse(JSON.stringify(item));
    // this.dataitem.title = 'Editar campaña';
    // $('#modal-campana').modal('show');
    // $('.collapse').collapse('hide');
    // setTimeout(() => {
    //   $('#modal-campana input[type="text"]')[0].focus();
    // }, 500);
    this.globals.passData = JSON.parse(JSON.stringify(item));
    this.ngZone.run(() => this.router.navigateByUrl('/crm-campanas/edit')).then();
  }
  Clonar(item:any) {
    this.globals.passData = JSON.parse(JSON.stringify(item));
    this.globals.passData.id = null;
    this.globals.passData.titulo = 'Copia - '+this.globals.passData.titulo;
    this.globals.passData.logs = null;
    this.ngZone.run(() => this.router.navigateByUrl('/crm-campanas/clone')).then();
  }
  SetTipoCampana() {
    if (!$.plantillastemp) return;
    this.plantillas = $.plantillastemp.filter(el => el.tipo == this.dataitem.tipo);
    if (this.plantillas.length) {
      this.dataitem.id_plantilla = this.plantillas[0].id;
      this.dataitem.asunto = this.plantillas[0].asunto;
    }
  }
  GuardarCampana() {
    if (
      !this.dataitem.id_plantilla
      || !this.dataitem.titulo
      || !this.dataitem.asunto
      || this.dataitem.id_plantilla == ''
      || this.dataitem.titulo == ''
      || this.dataitem.asunto == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    if (!this.dataitem.id || this.dataitem.id == '') {
      $.buttonloading('btn-guardar-campana', 'Enviando');
      this.globals.Api('/crm-campanas-add', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btn-guardar-campana');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.CargarGrid();
        $('#modal-campana').modal('hide');
      }, error => {
        $.buttoncancelloading('btn-guardar-campana');
      });
    } else {
      $.buttonloading('btn-guardar-campana', 'Enviando');
      this.globals.Api('/crm-campanas-edit', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btn-guardar-campana');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        toastr.success(`Datos guardados correctamente`, 'Guardado');
        this.CargarGrid();
        // $('#modal-campana').modal('hide');
      }, error => {
        $.buttoncancelloading('btn-guardar-campana');
      });
    }
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar campaña?",
      text: '¡No se podrán deshacer los cambios!',
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/crm-campanas-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.splice(this.data.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  LimpiarContactos() {
    if (!this.dataitem.contactos) this.dataitem.contactos = '';
    this.dataitem.contactos = this.dataitem.contactos.replace(/(\r\n|\n|\r)/gm, ' ');
    this.dataitem.contactos = this.dataitem.contactos.replace(/ /g, ',');
    if (this.dataitem.tipo == 'whatsapp') {
      let contactos = this.dataitem.contactos.split(',');
      for (let i = 0; i < contactos.length; i++) {
        let item = contactos[i];
        if (!item || item == '') continue;
        if (item.indexOf('+') == -1) item = '+'+item;
        contactos[i] = item;
      }
      this.dataitem.contactos = contactos.join(',');
    }
  }
  EnviarCampana() {
    if (!this.dataitem.contactos || this.dataitem.contactos == '') {
      swal('Complete contactos', 'Por favor, introduzca los contactos para continuar', 'error');return;
    }
    swal({
      title: "¿Enviar campaña?",
      type: "info",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      $.buttonloading('btn-enviar-campana', 'Enviando, por favor espere ...');
      this.globals.Api('/crm-campanas-enviar', {id: this.dataitem.id}).subscribe(data => {
        $.buttoncancelloading('btn-enviar-campana');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        toastr.success(`Su campaña ha sido enviada correctamente`, 'Campaña enviada');
        this.CargarGrid();
        $('#modal-campana').modal('hide');
      }, error => {
        $.buttoncancelloading('btn-enviar-campana');
      });
    });
  }
}
