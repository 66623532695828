import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html'
})
export class UsuariosComponent implements OnInit {
  public usuarios = [];
  public params:any = {
    page_index: 1,
    page_size: 50,
    total: 0,
    pages: 0,
    filtro: '',
    order: '',
    dir: '',
    tipofiltro: null,
    tipofiltrofecha: 'alta'
  };

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    this.CargarGrid();
    this.globals.GridOrderJS((order, dir) => {
      this.params.order = order;
      this.params.dir = dir;
      this.params.page_index = 1;
      this.CargarGrid();
      this.appref.tick();
    });
    /*
    $('thead input:checkbox').click(function() {
      var checkedStatus   = $(this).prop('checked');
      var table           = $(this).closest('table');
      $('tbody input:checkbox', table).each(function() {
          $(this).prop('checked', checkedStatus);
      });
    });
    */
  }

  CargarGrid(paginationload:boolean = true) {
    if (this.globals.GetData()) this.usuarios = this.globals.GetData();
    this.globals.Api('/usuarios', this.params).subscribe(data => {
      if (!data || !data.data) {
        this.usuarios = [];return;
      }
      this.globals.SaveData(data.data);
      this.usuarios = data.data;
      this.appref.tick();

      if (!paginationload) return;
      this.params.total = data.total;
      this.params.pages = Math.ceil(data.total / data.data.length);
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') {
      this.params.page_index -= 1;
    }
    if (dir == 'next') {
      this.params.page_index += 1;
    }
    this.CargarGrid(false);
  }
  Buscar() {
    this.params.page_index = 1;
    this.CargarGrid();
  }
  Filtrar(filtro:string) {
    $("#txtFiltroFecha input").val('');
    this.params.fechaalta = null;
    this.params.fechapagado = null;
    this.params.fechacaducidad = null;
    switch(filtro) {
      case 'proximocaducar': {
        this.params.tipofiltro = filtro;
        this.params.proximocaducar = true;
        this.params.caducado = null;
        this.Buscar();
      } break;
      case 'caducado': {
        this.params.tipofiltro = filtro;
        this.params.proximocaducar = null;
        this.params.caducado = true;
        this.Buscar();
      } break;
      case 'todo': {
        this.params.tipofiltro = null;
        this.params.proximocaducar = null;
        this.params.caducado = null;
        this.Buscar();
      } break;
    };
  }
  FiltrarFecha(filtro:string) {
    this.params.tipofiltrofecha = filtro;
    let fecha = moment($("#txtFiltroFecha").datepicker('getDate'), 'YYYY-MM-DD');
    switch(filtro) {
      case 'alta': {
        this.params.fechaalta = fecha;
        this.params.fechapagado = null;
        this.params.fechacaducidad = null;
        this.Buscar();
      } break;
      case 'pagado': {
        this.params.fechaalta = null;
        this.params.fechapagado = fecha;
        this.params.fechacaducidad = null;
        this.Buscar();
      } break;
      case 'caducidad': {
        this.params.fechaalta = null;
        this.params.fechapagado = null;
        this.params.fechacaducidad = fecha;
        this.Buscar();
      } break;
    };
  }
  Nuevo() {
    this.globals.passData = '';
    this.ngZone.run(() => this.router.navigateByUrl('/usuarios/add')).then();
  }
  Editar(item:any) {
    this.globals.passData = JSON.parse(JSON.stringify(item));
    this.ngZone.run(() => this.router.navigateByUrl('/usuarios/edit')).then();
  }
  CambiarAcceso(item:any) {
    this.globals.Api('/editar-acceso-usuario', {id: item.id}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      item.acceso = data.value;
      this.appref.tick();
    });
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar usuario?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/usuarios-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.usuarios.splice(this.usuarios.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }

}
