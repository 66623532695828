import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;

@Component({
  selector: 'app-seguimiento-servidores',
  templateUrl: './seguimiento-servidores.component.html',
  styles: [
  ]
})
export class SeguimientoServidoresComponent implements OnInit {
  public data:any = [];
  public params:any = {};
  public dataitem:any = {};

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    this.CargarDatos();
  }
  CargarDatos() {
    if (this.globals.GetData()) this.data = this.globals.GetData();
    this.globals.Api('/seguimientos-urls', this.params).subscribe((data:any) => {
      if (!data || !data.length) {
        this.data = [];
        this.globals.SaveData(this.data);
        return;
      }
      this.data = data;
      this.globals.SaveData(this.data);
    });
  }
  Buscar() {
    this.CargarDatos();
  }
  Nuevo() {
    $('#modal-edit-seguimiento').modal('show');
    Object.keys(this.dataitem).forEach(item => {this.dataitem[item] = ''});
    this.dataitem.activo = 1;
    setTimeout(() => {
      $('#modal-edit-seguimiento input[type="text"]')[0].focus();
    }, 500);
  }
  Editar(item:any) {
    this.dataitem = JSON.parse(JSON.stringify(item));
    $('#modal-edit-seguimiento').modal('show');
    setTimeout(() => {
      $('#modal-edit-seguimiento input[type="text"]')[0].focus();
    }, 500);
  }
  GuardarModal() {
    if (
      !this.dataitem.nombre
      || !this.dataitem.url
      || this.dataitem.nombre == ''
      || this.dataitem.url == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    $.buttonloading('btnGuardarModal', 'Enviando');
    if (!this.dataitem.id || this.dataitem.id == '') {
      this.globals.Api('/seguimientos-urls-add', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btnGuardarModal');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-edit-seguimiento').modal('hide');
        this.CargarDatos();
      }, error => {
        $.buttoncancelloading('btnGuardarModal');
      });
    } else {
      this.globals.Api('/seguimientos-urls-edit', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btnGuardarModal');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-edit-seguimiento').modal('hide');
        this.CargarDatos();
      }, error => {
        $.buttoncancelloading('btnGuardarModal');
      });
    }
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar seguimiento?",
      text: '¡No se podrán deshacer los cambios!',
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/seguimientos-urls-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.splice(this.data.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }

}
