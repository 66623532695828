import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let grapesjs: any;


@Component({
  selector: 'app-edit-campana',
  templateUrl: './edit-campana.component.html'
})
export class CRMEditCampanaComponent implements OnInit {
  public title:string = 'Nueva campaña';
  public config:any = {};
  public data:any = {tipo: 'email', idioma: 'es'};
  public plantillas:any = [];
  
  public clientes:any = [];
  public params:any = {
    page_index: 1,
    page_size: 5,
    tipo: ''
  };
  public listas:any = [];
  public id_cuenta_whatsapp:any = '';

  constructor(
    public sanitizer: DomSanitizer,
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(1);
    $.checkrolredirect(2);
    $.checkrolredirect(3);
    $.checkrolredirect(4);
    if (window.location.pathname.indexOf('add') != -1) this.globals.passData = null;
    if (this.globals.passData && this.globals.passData != '' && Object.keys(this.globals.passData).length > 0) this.data = this.globals.passData;
    if (!this.data.id || this.data.id == '') {
      this.title = 'Nueva campaña';
    } else {
      this.title = this.data.titulo;
    }
    this.globals.Api('/crm-plantillas').subscribe((data:any) => {
      if (!data || !data.length) return;
      this.plantillas = data;
      $.plantillastemp = this.plantillas;
      this.SetTipoCampana(false);
    });
    
    this.CargarGrid();
    this.globals.GridOrderJS((order, dir) => {
      this.params.order = order;
      this.params.dir = dir;
      this.params.page_index = 1;
      this.CargarGrid();
      this.appref.tick();
    });
    let that = this;
    $('thead input:checkbox').click(function() {
      var checkedStatus   = $(this).prop('checked');
      var table           = $(this).closest('table');
      $('tbody input:checkbox', table).each(function() {
        $(this).prop('checked', checkedStatus);
      });
      if ($('tbody input:checked').length) that.data.puedeseleccionar = true;
      else that.data.puedeseleccionar = false;
      that.appref.tick();
    });
    this.globals.Api('/crm-listas').subscribe((data:any) => {
      if (!data || !data.length) return;
      this.listas = data;
      if (!this.data.tipo && this.listas.length) this.data.tipo = this.listas[0].tipo;
    });
    this.CargarConfig();

    setTimeout(() => {
      $('.pie-chart').easyPieChart({
        barColor: $(this).data('bar-color') ? $(this).data('bar-color') : '#777777',
        trackColor: $(this).data('track-color') ? $(this).data('track-color') : '#eeeeee',
        lineWidth: $(this).data('line-width') ? $(this).data('line-width') : 3,
        size: $(this).data('size') ? $(this).data('size') : '80',
        animate: 800,
        scaleColor: false
      });
    }, 200);
  }

  CargarConfig() {
    this.globals.Api('/crm-config').subscribe((data:any) => {
      if (!data) return;
      this.config = data;
      if (this.config.cuentas_whatsapp && this.config.cuentas_whatsapp.length > 1) {
        this.id_cuenta_whatsapp = this.config.cuentas_whatsapp[0].id;
      }
    });
  }
  CargarLogEnvios() {
    if (!this.data.id || this.data.id == '') return;
    this.globals.Api('/crm-campanas-logs', {id: this.data.id}).subscribe((data:any) => {
      if (!data || !data.length) return;
      this.data.logs_envio = data;
      this.appref.tick();
    });
  }
  ChangePlantilla() {
    setTimeout(() => {
      this.SetTipoCampana(false);
    }, 100);
  }

  GetTotalContactos() {
    if (!this.data.contactos) return 0;
    if (this.data.contactos == '') return 0;
    return this.data.contactos.split(',').length;
  }
  SetTipoCampana(cleancontactos:boolean = true) {
    if (!$.plantillastemp) return;
    function urlify(string) {
      const urls = string.match(/((((ftp|https?):\/\/)|(w{3}\.))[\-\w@:%_\+.~#?,&\/\/=]+)/g);
      if (urls) {
        urls.forEach(function (url) {
          string = string.replace(url, '<a target="_blank" href="' + url + '">' + url + "</a>");
        });
      }
      return string.replace("(", "<br/>(");
    }
    this.plantillas = $.plantillastemp.filter(el => el.tipo == this.data.tipo);
    if (this.plantillas.length) {
      let plantilla:any = null;
      if (this.data.id_plantilla) plantilla = this.plantillas.find(el => el.id == this.data.id_plantilla);
      if (!plantilla) plantilla = this.plantillas[0];
      this.data.id_plantilla = plantilla.id;
      this.data.asunto = plantilla.asunto;
      if (plantilla.tipo == 'email') {
        this.data.email_preview = this.sanitizer.bypassSecurityTrustHtml(plantilla.plantilla);
        this.data.whatsapp_preview = null;
      }
      if (plantilla.tipo == 'whatsapp') {
        this.data.email_preview = null;
        this.data.whatsapp_preview = plantilla.plantilla.replace(/(?:\r\n|\r|\n)/g, '<br>');
        this.data.whatsapp_preview = urlify(this.data.whatsapp_preview);
      }
    }
    if (cleancontactos) this.data.contactos = '';
    this.appref.tick();
  }
  Cancelar() {
    this.ngZone.run(() => this.router.navigateByUrl('/crm-campanas')).then();
  }
  Guardar() {
    if (
      !this.data.id_plantilla
      || !this.data.titulo
      || !this.data.asunto
      || this.data.id_plantilla == ''
      || this.data.titulo == ''
      || this.data.asunto == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    if (!this.data.id || this.data.id == '') {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/crm-campanas-add', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.globals.Api('/crm-campanas', {id: this.data.id}).subscribe((data:any) => {
          if (!data || !data.length) return;
          this.data = data[0];
          this.SetTipoCampana(false);
        });
      }, error => {
        $.buttoncancelloading('.btn-guardar');
      });
    } else {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/crm-campanas-edit', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        toastr.success(`Datos guardados correctamente`, 'Guardado');
      }, error => {
        $.buttoncancelloading('.btn-guardar');
      });
    }
  }
  LimpiarContactos() {
    if (!this.data.contactos) this.data.contactos = '';
    this.data.contactos = this.data.contactos.replace(/(\r\n|\n|\r)/gm, ' ');
    this.data.contactos = this.data.contactos.replace(/ /g, ',');
    if (this.data.tipo == 'whatsapp') {
      let contactos = this.data.contactos.split(',');
      for (let i = 0; i < contactos.length; i++) {
        let item = contactos[i];
        if (!item || item == '') continue;
        if (item.indexOf('+') == -1) item = '+'+item;
        contactos[i] = item;
      }
      this.data.contactos = contactos.join(',');
    }
  }
  EnviarCampana() {
    if (!this.data.contactos || this.data.contactos == '') {
      swal('Complete contactos', 'Por favor, introduzca los contactos para continuar', 'error');return;
    }
    swal({
      title: "¿Enviar campaña?",
      type: "info",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      $.buttonloading('.btn-enviar-campana', 'Enviando, por favor espere ...');
      let params:any = {id: this.data.id};
      if (this.id_cuenta_whatsapp) params.fbid = this.config.cuentas_whatsapp.find((el:any) => el.id == this.id_cuenta_whatsapp).whatsapp_fbid;
      if (this.id_cuenta_whatsapp) params.fbtoken = this.config.cuentas_whatsapp.find((el:any) => el.id == this.id_cuenta_whatsapp).whatsapp_access_token;
      this.globals.Api('/crm-campanas-enviar', params).subscribe(data => {
        $.buttoncancelloading('.btn-enviar-campana');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        toastr.success(`Su campaña ha sido enviada correctamente`, 'Campaña enviada');
        this.data.logs = JSON.stringify(data.value);
        this.CargarLogEnvios();
      }, error => {
        $.buttoncancelloading('.btn-enviar-campana');
      });
    });
  }
  
  AddContactos() {
    if (this.params.page_index != 1) {
      this.Buscar();
    }
    $('#modal-seleccion-clientes').modal('show');
    let that = this;
    $('tbody input:checkbox').off('click').click(function() {
      if ($('tbody input:checked').length) that.data.puedeseleccionar = true;
      else that.data.puedeseleccionar = false;
      that.appref.tick();
    });
  }
  CargarGrid(paginationload:boolean = true) {
    this.LimpiarChecks();
    this.globals.Api('/crm-clientes', this.params).subscribe((data:any) => {
      if (!data || !data.length) {
        this.clientes = [];
        return;
      }
      this.clientes = data;
      this.appref.tick();
      if (!paginationload) return;
      this.globals.Api('/crm-clientes/total', this.params).subscribe((data:any) => {
        if (!data || !data.length) return;
        this.params.total = data[0].total;
        this.params.pages = Math.ceil(this.params.total / this.params.page_size);
      });
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') this.params.page_index -= 1;
    if (dir == 'next') this.params.page_index += 1;
    this.CargarGrid(true);
  }
  LimpiarChecks() {
    $('thead input:checkbox').each(function() {
      $(this).prop('checked', false);
    });
    $('tbody input:checkbox').each(function() {
      $(this).prop('checked', false);
    });
    this.data.puedeseleccionar = false;
  }
  Buscar() {
    this.params.page_index = 1;
    this.CargarGrid();
  }
  SeleccionarContacto(event:any) {
    $(event.target).parents('tr').find('.csscheckbox').click();
    if ($('tbody input:checked').length) this.data.puedeseleccionar = true;
  }
  ReemplazarContactos() {
    if (!this.data.contactos) this.data.contactos = '';
    let that = this;
    let contactos:any = [];
    $('tbody input:checked').each(function() {
      let id = $(this).attr('data-id');
      let item = that.clientes.find(el => el.id == id);
      if (item) {
        if (that.data.tipo == 'email') {
          contactos.push(item.email);
        }
        if (that.data.tipo == 'whatsapp') {
          contactos.push(item.telefono);
        }
      }
    });
    if (!contactos.length) {
      $('#modal-seleccion-clientes').modal('hide');
      return;
    }
    this.data.contactos = contactos.join(',');
    $('#modal-seleccion-clientes').modal('hide');
    this.Guardar();
  }
  AnadirContactos() {
    if (!this.data.contactos) this.data.contactos = '';
    let that = this;
    let contactos:any = [];
    $('tbody input:checked').each(function() {
      let id = $(this).attr('data-id');
      let item = that.clientes.find(el => el.id == id);
      if (item) {
        if (that.data.tipo == 'email') {
          if (that.data.contactos.indexOf(item.email) != -1) return;
          contactos.push(item.email);
        }
        if (that.data.tipo == 'whatsapp') {
          if (that.data.contactos.indexOf(item.telefono) != -1) return;
          contactos.push(item.telefono);
        }
      }
    });
    if (!contactos.length) {
      $('#modal-seleccion-clientes').modal('hide');
      return;
    }
    if (this.data.contactos.slice(-1)) this.data.contactos += ',';
    this.data.contactos += contactos.join(',');
    // $('#modal-seleccion-clientes').modal('hide');
    // toastr.success(`Añadidos ${contactos.length} contactos`, 'Contactos añadidos');
    this.LimpiarChecks();
    this.Guardar();
  }
  DelContactos() {
    this.data.contactos = '';
  }
  ImportarTodos() {
    let params:any = JSON.parse(JSON.stringify(this.params));
    params.page_index = null;
    params.page_size = null;
    this.globals.Api('/crm-clientes', params).subscribe((data:any) => {
      if (!data || !data.length) return;
      if (!this.data.contactos) this.data.contactos = '';
      let contactos:any = [];
      if (this.data.tipo == 'email') {
        for (let item of data) {
          if (!item.email || item.email.toString().trim() == '') continue;
          contactos.push(item.email);
        }
      }
      if (this.data.tipo == 'whatsapp') {
        for (let item of data) {
          if (!item.telefono || item.telefono.toString().trim() == '') continue;
          if (!item.telefono.startsWith('+')) continue;
          contactos.push(item.telefono);
        }
      }
      this.data.contactos = contactos.join(',');
      $('#modal-seleccion-clientes').modal('hide');
      this.Guardar();
    });
  }
}
