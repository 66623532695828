<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">Campañas</h1>
                                <button class="btn btn-xs btn-default middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>Campañas</li>
                            </ul>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center no-wrap">
                          <label class="col-form-label inline-block m-r-10">Mostrar</label>
                          <select class="form-control inline-block" (change)="Buscar()" [(ngModel)]="params.page_size" style="width:auto;">
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option>200</option>
                          </select>
                        </div>
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto text-center">
                          <div class="input-group width-100">
                            <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                            <div class="input-group-append cursor" (click)="Buscar()">
                              <span class="input-group-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center">
                          <a class="btn btn-default btn-primary btn-block" (click)="Nuevo()"><i class="fa fa-plus"></i> Nueva campaña</a>
                        </div>
                    </div>
                </div>

                <div class="table-responsive table-grid block full break-padding">
                    
                    <div class="no-resultados text-center" *ngIf="!data || !data.length">
                      <div>Aún no se han creado campaña.</div>
                      <a class="btn btn-primary m-t-10 m-b-30" (click)="Nuevo()"><i class="fa fa-plus"></i> Crear nueva campaña</a>
                    </div>
                    <table class="table table-striped table-bordered table-vcenter table-hover" *ngIf="data && data.length">
                        <thead>
                            <tr>
                              <th order="titulo">Campaña</th>
                              <th style="width:130px" order="alta">Alta</th>
                              <th style="width:200px" order="asunto">Asunto</th>
                              <th style="width:200px">Contactos</th>
                              <th style="width:80px">Leído</th>
                              <th style="width:50px">Clonar</th>
                              <th style="width:50px">Editar</th>
                              <th style="width:50px">Borrar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of data" class="cursor">
                              <td class="font-bold text-primary" (click)="Editar(item)">
                                <div>{{item.titulo}}</div>
                              </td>
                              <td>{{item.alta_format}}</td>
                              <td>{{item.asunto}}</td>
                              <td class="breakword">{{item.contactos_resumen}}</td>
                              <td class="text-center">
                                <div *ngIf="item.tipo == 'email'">
                                  <div class="pie-chart" [attr.data-percent]="item.porcent_leido" 
                                    data-size="50" data-line-width="2" data-bar-color="#13bb15" data-track-color="#ebeef2">
                                    <span class="font-bold font-16" style="color: #13bb15;margin-top:-8px;display:block;">{{item.porcent_leido}}%</span>
                                  </div>
                                </div>
                              </td>
                              <td class="text-center btntd"><a class="btn btn-secondary btn-xs" (click)="Clonar(item)"><i class="fa fa-clone text-white"></i></a></td>
                              <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="Editar(item)"><i class="fa fa-pencil text-white"></i></a></td>
                              <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="Borrar(item)"><i class="fa fa-trash text-white"></i></a></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="dataTables_paginate paging_simple_numbers m-r-10" *ngIf="params.pages > 1">
                      <ul class="pagination justify-content-end">
                        <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}">
                          <a (click)="Paging('prev')" class="page-link">Anterior</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index > 1">
                          <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
                        </li>
                        <li class="paginate_button page-item active">
                          <a class="page-link cursor">{{params.page_index}}</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
                          <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
                        </li>
                        <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}">
                          <a (click)="Paging('next')" class="page-link">Siguiente</a>
                        </li>
                      </ul>
                    </div>
                    
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>

<div class="modal inmodal fade" id="modal-campana" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog modal-lg">
      <div class="modal-content">
      <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
          <h4 class="modal-title">{{dataitem.title}}</h4>
      </div>
      <div class="modal-body">
                    
          <div class="row">
            <div class="col-md-8 col-xs-12">
              <div class="form-group">
                <label>Título <span class="text-danger">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="dataitem.titulo" maxlength="100">
              </div>
            </div>
            <div class="col-md-4 col-xs-12">
              <div class="form-group">
                <label>Tipo <span class="text-danger">*</span></label>
                <select class="form-control" [(ngModel)]="dataitem.tipo" (change)="SetTipoCampana()">
                    <option value="email" selected>Email</option>
                    <option value="whatsapp" selected>WhatsApp</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8 col-xs-12">
              <div class="form-group">
                <label>Asunto <span class="text-danger">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="dataitem.asunto" maxlength="100">
              </div>
            </div>
            <div class="col-md-4 col-xs-12">
              <div class="form-group">
                <label>Plantilla <span class="text-danger">*</span></label>
                <select class="form-control" [(ngModel)]="dataitem.id_plantilla">
                  <option [value]="item.id" *ngFor="let item of plantillas">{{item.asunto}}</option>
                </select>
              </div>
            </div>
          </div>
          
          <div class="form-group">
            <label>Contactos <small class="m-l-30" style="font-weight:normal;">Separados por coma (,)</small></label>
            <textarea class="form-control" rows="6" [(ngModel)]="dataitem.contactos" (keyup)="LimpiarContactos()"></textarea>
          </div>

          <div class="form-group" *ngIf="dataitem.logs && dataitem.logs != ''">
            <a class="btn btn-default" data-toggle="collapse" href="#logs-campana">Ver logs último envío</a>
            <div class="collapse" id="logs-campana">
              <p>{{dataitem.logs}}</p>
            </div>
          </div>

      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
          <button id="btn-guardar-campana" type="button" class="btn btn-primary" (click)="GuardarCampana()">Guardar</button>
          <button id="btn-enviar-campana" type="button" class="btn btn-info" style="margin-left:30px" (click)="EnviarCampana()" *ngIf="dataitem.id && dataitem.id != ''">Enviar campaña <i class="fa fa-send"></i></button>
      </div>
      </div>
  </div>
</div>