<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">Contactos</h1>
                                <button class="btn btn-xs btn-default middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button>
                                <div *ngIf="params.total" class="font-14">{{params.total}} en total</div>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>Contactos</li>
                            </ul>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center no-wrap">
                          <label class="col-form-label inline-block m-r-10">Mostrar</label>
                          <select class="form-control inline-block" (change)="Buscar()" [(ngModel)]="params.page_size" style="width:auto;">
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option>200</option>
                          </select>
                        </div>
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto text-center">
                          <div class="input-group width-100">
                            <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                            <div class="input-group-append cursor" (click)="Buscar()">
                              <span class="input-group-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center">
                          <a class="btn btn-primary btn-block" (click)="Nuevo()"><i class="fa fa-plus"></i> Nuevo contacto</a>
                        </div>
                    </div>
                </div>

                <div class="row m-b-10">
                  <div class="col-sm-6 col-xs-12">
                    <label class="inline-block">Lista</label>
                    <select class="form-control inline-block m-l-10" style="width:auto;min-width:220px" [(ngModel)]="params.tipo" (change)="Buscar()">
                      <option value="">Todas las listas</option>
                      <option [value]="item.tipo" *ngFor="let item of listas">{{item.tipo}}</option>
                    </select>
                    <a class="btn btn-light inline-block m-l-10" *ngIf="params.tipo && params.tipo != ''" (click)="DelLista(params.tipo)"><i class="fa fa-trash m-r-5"></i>Borrar Contactos de esta lista</a>
                  </div>
                  <div class="col-sm-6 col-xs-12 text-right">
                    <a class="btn btn-light" (click)="Importar()"><i class="fa fa-file-excel-o m-r-5"></i> Importar</a>
                  </div>
                </div>

                <div class="table-responsive table-grid block full break-padding">
                    
                    <div class="no-resultados text-center" *ngIf="!data || !data.length">
                      <div>Aún no se han creado contactos.</div>
                      <a class="btn btn-primary m-t-10 m-b-30" (click)="Nuevo()"><i class="fa fa-plus"></i> Crear nuevo contacto</a>
                    </div>
                    <table class="table table-striped table-bordered table-vcenter table-hover" *ngIf="data && data.length">
                        <thead>
                            <tr>
                              <th order="asunto">Contacto</th>
                              <th style="width:130px" order="alta">Alta</th>
                              <th style="width:130px" order="tipo">Lista</th>
                              <th style="width:50px">Editar</th>
                              <th style="width:50px">Borrar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of data" class="cursor">
                              <td (click)="Editar(item)">
                                <div class="font-bold text-primary" *ngIf="item.empresa">{{item.empresa}}</div>
                                <div class="font-bold text-primary" *ngIf="!item.empresa && item.nombre">{{item.nombre}} {{item.apellidos}}</div>
                                <div *ngIf="item.email"><i class="fa fa-envelope-o"></i> {{item.email}}</div>
                                <div *ngIf="item.telefono"><i class="fa fa-phone"></i> {{item.telefono}}</div>
                              </td>
                              <td>{{item.alta_format}}</td>
                              <td>{{item.tipo}}</td>
                              <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="Editar(item)"><i class="fa fa-pencil text-white"></i></a></td>
                              <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="Borrar(item)"><i class="fa fa-trash text-white"></i></a></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="dataTables_paginate paging_simple_numbers m-r-10" *ngIf="params.pages > 1">
                      <ul class="pagination justify-content-end">
                        <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}">
                          <a (click)="Paging('prev')" class="page-link">Anterior</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index > 1">
                          <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
                        </li>
                        <li class="paginate_button page-item active">
                          <a class="page-link cursor">{{params.page_index}}</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
                          <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
                        </li>
                        <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}">
                          <a (click)="Paging('next')" class="page-link">Siguiente</a>
                        </li>
                      </ul>
                    </div>
                    
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>

<div class="modal inmodal fade" id="modal-importar-clientes" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog modal-lg">
      <div class="modal-content">
      <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
          <h4 class="modal-title"><i class="fa fa-file-excel-o m-r-5"></i> Asistente de importación</h4>
      </div>
      <div class="modal-body text-center p-t-20 p-b-20">

        <ng-container *ngIf="step == 1">
          <div class="m-t-20 m-b-20">

            <div class="row">
              <div class="col-md-6 text-left">
                <div class="form-group">
                    <label>Lista</label><br>
                    <input type="text" class="form-control inline-block" placeholder="Todas las listas" [(ngModel)]="dataimportar.tipo" maxlength="100" list="listas" style="width:auto;min-width:220px">
                    <datalist id="listas">
                      <option [value]="item.tipo" *ngFor="let item of listas"></option>
                    </datalist>
                </div>
                <div class="form-group">
                  <label>Empezar a leer en la fila:</label><br>
                  <select class="form-control inline-block" [(ngModel)]="dataimportar.min_fila" style="width:auto;">
                    <option [value]="fila" *ngFor="let fila of filas">{{fila}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6 text-left">
                <div class="form-group">
                  <label>&nbsp;</label><br>
                  <a class="btn btn-primary" (click)="SelExcel()"><i class="fa fa-file-excel-o m-r-5"></i> Seleccionar archivo excel</a>
                  <div *ngIf="dataimportar.docname">Documento seleccionado: {{dataimportar.docname}}</div>
                </div>
                <p>Incluye en tu archivo una fila de cabecera con el nombre de los campos a importar</p>
              </div>
            </div>

          </div>
        </ng-container>
        <ng-container *ngIf="step == 2">
          <h2 class="font-18">Asignación de campos</h2>
          <div class="text-left">
            
            <div class="table-responsive block full break-padding">
              <table class="table table-striped table-bordered table-vcenter table-hover">
                <thead>
                    <tr>
                        <th style="width:33%;">Campos encontrados</th>
                        <th style="width:33%;">Valores ejemplo</th>
                        <th style="width:33%;">Asignación</th>
                    </tr>
                    <ng-container *ngFor="let item of dataimportar.primera_fila_keys">
                      <tr>
                        <td style="padding: 5px 10px;">
                          {{item}}
                        </td>
                        <td style="padding: 5px 10px;">
                          {{dataimportar.primera_fila[item]}}
                        </td>
                        <td style="padding: 5px 10px;">
                          <select class="form-control" (change)="AsignarCampo(item, $event)" [attr.data-asignacion]="item">
                            <option value="">No importar</option>
                            <option [value]="item.columna" *ngFor="let item of columnas">{{item.columna}}</option>
                          </select>
                        </td>
                      </tr>
                    </ng-container>
                </thead>
              </table>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="step == 3">
          <h2 class="font-18">Resumen</h2>
          <p>Contactos a importar: <b>{{dataimportar.contactos.length-1}}</b></p>
          <p>Lista: 
            <b><span *ngIf="dataimportar.tipo == ''">Todas las listas</span><span *ngIf="dataimportar.tipo != ''">{{dataimportar.tipo}}</span></b>
          </p>
        </ng-container>

      </div>
      <div class="modal-footer flex">
        <button type="button" class="btn btn-white" data-dismiss="modal" style="margin-right: auto;">Cancelar</button>
        <button id="btn-importar-anterior" type="button" class="btn btn-secondary" (click)="Anterior()" *ngIf="step > 1">Anterior</button>
        <button id="btn-importar-siguiente" type="button" class="btn btn-primary" style="margin-left: 15px;" *ngIf="step < 3" (click)="Siguiente()">Siguiente <i class="fa fa-chevron-right"></i></button>
        <button id="btn-importar" type="button" class="btn btn-primary" style="margin-left: 15px;" *ngIf="step == 3" (click)="ImportarContactos()">Importar <i class="fa fa-check"></i></button>
      </div>
      </div>
  </div>
</div>