import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;

@Component({
  selector: 'app-edit-cliente',
  templateUrl: './edit-cliente.component.html'
})
export class CRMEditClienteComponent implements OnInit {
  public title:string = 'Nuevo contacto';
  public data:any = {};
  public imgsubir:string = '';
  public listas:any = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(1);
    $.checkrolredirect(2);
    $.checkrolredirect(3);
    $.checkrolredirect(4);
    if (window.location.pathname.indexOf('add') != -1) this.globals.passData = null;
    if (this.globals.passData && this.globals.passData != '' && Object.keys(this.globals.passData).length > 0) this.data = this.globals.passData;
    if (!this.data.id || this.data.id == '') {
      this.title = 'Nuevo contacto';
    } else {
      this.title = this.data.nombre + (this.data.apellidos ? ' ' + this.data.apellidos : '');
    }
    this.globals.Api('/crm-listas').subscribe((data:any) => {
      if (!data || !data.length) return;
      this.listas = data;
      if (!this.data.tipo && this.listas.length) this.data.tipo = this.listas[0].tipo;
    });
  }
  
  SelecImagen() {
    let input = this.globals.FileInput('image/*', event => {
      this.globals.Base64Image(input, base64 => {
        this.data.imagen = base64;
        this.imgsubir = base64;
        this.appref.tick();
      });
    }, 800);
    input.click();
  }
  BorrarImagen(event:any) {
    event.stopPropagation();
    swal({
      title: "¿Borrar imagen del contacto?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      if (!this.data.id || this.data.id == '') {
        this.imgsubir = '';
        this.data.imagen = '';
        return;
      }
      this.globals.Api('/crm-clientes-del-imagen', {id: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.imgsubir = '';
        this.data.imagen = '';
        this.appref.tick();
      });
    });
  }
  
  DatosLocalidad() {
    this.globals.Api('/localidad', {codpostal: this.data.codpostal}).subscribe(data => {
      if (!data) {
        this.data.localidad = '';
        this.data.provincia = '';
        this.appref.tick();
        return;
      }
      this.data.localidad = data.localidad;
      this.data.provincia = data.provincia;
      this.appref.tick();
    });
  }

  LimpiarTelefono() {
    if (!this.data.telefono) this.data.telefono = '';
    if (this.data.telefono.indexOf('+') == -1) this.data.telefono = '+'+this.data.telefono;
  }
  Cancelar() {
    this.ngZone.run(() => this.router.navigateByUrl('/crm-clientes')).then();
  }
  Guardar() {
    if (
      (!this.data.email && !this.data.telefono)
      || (this.data.email == '' && this.data.telefono == '')
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (this.data.email && !this.globals.ValidateEmail(this.data.email)) {
      swal('Email no válido', 'Por favor, introduzca un email válido.', 'error');return;
    }

    this.data.img = null;
    if (this.imgsubir != '') this.data.img = this.imgsubir;

    if (!this.data.id || this.data.id == '') {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/crm-clientes-add', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          if (data.value == 'Email repetido') {
            swal('Email existente', 'Existe un usuario con este email', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.Cancelar();
      }, error => {
        $.buttoncancelloading('.btn-guardar');
      });
    } else {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/crm-clientes-edit', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          if (data.value == 'Email repetido') {
            swal('Email existente', 'Existe un usuario con este email', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        swal('Guardado', 'Datos guardados correctamente', 'success');
      }, error => {
        $.buttoncancelloading('.btn-guardar');
      });
    }
  }

}
