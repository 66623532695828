<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">Generador Logs Acceso</h1>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>Generador Logs Acceso</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="block">
                    <div class="block-title"><h2>Parámetros</h2></div>
                    <div class="row">
                        <div class="col-lg-8 col-md-8 col-sm-12">
                            <div class="form-group">
                              <label>Dominio</label>
                              <input type="url" class="form-control" [(ngModel)]="data.dominio" maxlength="80" placeholder="ejemplo.com" (keyup)="CargarEmails()">
                            </div>
                            <div class="flex" style="flex-wrap: wrap; gap: 1rem;">
                                <div class="form-group">
                                  <label>Email 1</label>
                                  <input type="email" class="form-control" [(ngModel)]="data.email1" maxlength="80">
                                </div>
                                <div class="form-group">
                                  <label>Email 2</label>
                                  <input type="email" class="form-control" [(ngModel)]="data.email2" maxlength="80">
                                </div>
                                <div class="form-group">
                                  <label>Email 3</label>
                                  <input type="email" class="form-control" [(ngModel)]="data.email3" maxlength="80">
                                </div>
                                <div class="form-group">
                                  <label>Email 4</label>
                                  <input type="email" class="form-control" [(ngModel)]="data.email4" maxlength="80">
                                </div>
                                <div class="form-group">
                                  <label>Email 5</label>
                                  <input type="email" class="form-control" [(ngModel)]="data.email5" maxlength="80">
                                </div>
                                <div class="form-group">
                                  <label>Email 6</label>
                                  <input type="email" class="form-control" [(ngModel)]="data.email6" maxlength="80">
                                </div>
                                <div class="form-group">
                                  <label>Email 7</label>
                                  <input type="email" class="form-control" [(ngModel)]="data.email7" maxlength="80">
                                </div>
                                <div class="form-group">
                                  <label>Email 8</label>
                                  <input type="email" class="form-control" [(ngModel)]="data.email8" maxlength="80">
                                </div>
                                <div class="form-group">
                                  <label>Email 9</label>
                                  <input type="email" class="form-control" [(ngModel)]="data.email9" maxlength="80">
                                </div>
                                <div class="form-group">
                                  <label>Email 10</label>
                                  <input type="email" class="form-control" [(ngModel)]="data.email10" maxlength="80">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="form-group">
                              <label>Tiempo</label>
                              <select class="form-control" [(ngModel)]="data.tiempo">
                                  <option value="6m">6 meses</option>
                                  <option value="3m">3 meses</option>
                                  <option value="1m">1 mes</option>
                              </select>
                            </div>
                            <a class="btn btn-primary btn-block" (click)="Generar()">Generar <i class="fa fa-download"></i></a>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-9 col-md-9 col-sm-12">
                        <div class="block">
                            <div class="block-title"><h2>Logs</h2></div>
                            <div class="logs m-b-30" *ngIf="data.logs_html" [innerHTML]="data.logs_html"></div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12">
                        <a class="btn btn-primary" (click)="Imprimir()"><i class="fa fa-file-pdf-o"></i> Imprimir PDF</a>
                    </div>
                </div>
                
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>