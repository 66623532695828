<div id="whatsapp-container" class="container">
    <div class="row">
        <div class="col-md-4 col-sm-12 {{vista == '' || vista == 'contactos' ? null : 'hidden'}}">
            <ul class="list-group whatsapp-contactos">
                <li class="list-group-item active">
                    <ng-container *ngIf="!buscando">
                        <i class="fa fa-whatsapp"></i> Contactos
    
                        <a class="btn absolute" (click)="buscando = true"
                            style="background-color: transparent;color:#FFF;font-size:15px;top:2px;right:5px;"><i class="fa fa-search"></i></a>
                        
                        <select class="form-control m-t-5"
                            (change)="ChangeCuenta($event)"
                            *ngIf="config.cuentas_whatsapp && config.cuentas_whatsapp.length">
                            <option [value]="item.id" *ngFor="let item of this.config.cuentas_whatsapp">{{item.nombre}} - <span>{{item.whatsapp_fbid}}</span></option>
                        </select>
                    </ng-container>
                    <ng-container *ngIf="buscando">
                        <input type="search" class="form-control p-r-40" placeholder="Contacto, mensaje ..." [(ngModel)]="busqueda"
                            (keyup)="Buscar()" (keyup.enter)="Buscar()">
                        <a class="btn absolute" (click)="CancelarBusqueda()"
                                style="background-color: transparent;color:#707070;font-size:15px;top:9px;right:15px;"><i class="fa fa-close"></i></a>
                    </ng-container>
                    <!-- <input class="form-control m-t-5" placeholder="Buscar contacto ..." (keyup)="BuscarContacto($event)"> -->
                </li>
                <div class="whatsapp-contactos-container">
                    <ng-container *ngFor="let item of contactos">
                        <li class="list-group-item {{item.active ? 'active-contacto' : null}} cursor"
                            (click)="SeleccionarContacto(item)">
                            <a class="font-bold">
                                <span *ngIf="item.nombre" style="color: #545454;">{{item.nombre}}</span>
                                <span *ngIf="item.numero">{{item.numero}}</span>
                                <span *ngIf="item.fecha_format" class="font-12 text-black">Recibido el: {{item.fecha_format}}</span>
                                <span *ngIf="item.fecha_format" class="font-12 text-black">Hace {{globals.PostedDate(item.fecha)}}</span>
                                <span *ngIf="item.cuenta" class="font-italic font-12 text-muted">{{item.cuenta}}</span>
                            </a>
                            <span class="badge" *ngIf="item.total_noleidos">{{item.total_noleidos}}</span>
                            <span class="flecha" *ngIf="item.active"><i class="fa fa-chevron-right"></i></span>
                        </li>
                    </ng-container>
                </div>
            </ul>
        </div>
        <div class="col-md-8 col-sm-12 {{vista == '' || vista == 'chat' ? null : 'hidden'}}">
            <ul class="list-group whatsapp-chat">
                <li class="list-group-item active">
                    <ng-container *ngIf="!buscando_mensaje">
                        <ng-container *ngIf="!contacto">
                            <span>Seleccione un contacto</span>
                        </ng-container>
                        <ng-container *ngIf="contacto">
                            <a class="btn m-r-15" *ngIf="movil" (click)="Atras()"
                                style="background-color: transparent;color:#FFF;font-size:15px;">
                                <i class="fa fa-chevron-left m-r-5"></i> Atrás
                            </a>
                            <span *ngIf="contacto && contacto.nombre" style="line-height:1.1">
                                {{contacto.nombre}}
                                <span *ngIf="contacto.numero" style="font-size:13px;font-weight: normal;font-style: italic;"><br>{{contacto.numero}}</span>
                            </span>&nbsp;
                            <span *ngIf="contacto && contacto.numero && !contacto.nombre">{{contacto.numero}}</span>
                            <a class="btn m-l-auto m-r-30" (click)="buscando_mensaje = true"
                                style="background-color: transparent;color:#FFF;font-size:15px;"><i class="fa fa-search"></i></a>
                            <a class="btn btn-danger btn-xs absolute" style="right:10px" (click)="DelConversacion()"><i class="fa fa-trash"></i></a>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="buscando_mensaje">
                        <input type="search" class="form-control p-r-40" placeholder="Busca en la conversación ..." [(ngModel)]="busqueda_mensaje"
                            (keyup)="BuscarConversacion()" (keyup.enter)="BuscarConversacion()">
                        <a class="btn absolute" (click)="CancelarBusquedaConversacion()"
                                style="background-color: transparent;color:#707070;font-size:15px;top:9px;right:15px;"><i class="fa fa-close"></i></a>
                    </ng-container>
                </li>
                <li class="list-group-item whatsapp-chat-container">
                    
                    <div *ngIf="!contacto" class="flex" style="height:300px;align-items:center;justify-content:center;flex-direction:column;">
                        <p>Selecciona un contacto de la lista para ver la conversación</p>
                    </div>
                    <div class="message {{item.origen ? 'received' : null}}" *ngFor="let item of mensajes">
                        <div class="message-inner">
                            <p [innerHTML]="item.mensaje" *ngIf="item.mensaje"></p>
                            <a [href]="item.imagen" *ngIf="item.imagen" class="link-img-principal">
                                <img [src]="item.imagen" alt="" class="img-principal">
                            </a>
                            <!-- <span class="date">Hace {{globals.PostedDate(item.alta)}}</span> -->
                            <span class="date">{{item.alta_format}} {{item.hora}}</span>
                        </div>
                    </div>
    
                </li>
                <li class="list-group-item whatsapp-write-container {{mensajes && mensajes.length ? null : 'hidden'}}">
                    <div class="lead emoji-picker-container">
                        <textarea class="form-control" id="txtmensaje" data-emojiable="true"></textarea>
                        <label class="font-normal font-12 p-l-5"><input type="checkbox" id="chk-enviar-enter" class="m-r-5"> Enviar al pulsar "enter"</label>
                    </div>
                    <!-- <a class="btn btn-emoji btn-primary"><i class="fa fa-smile-o"></i></a> -->
                    <a id="btnenviar" class="btn btn-circle btn-primary" (click)="Enviar()"><i class="fa fa-send"></i></a>
                </li>
            </ul>
        </div>
    </div>
</div>