<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row-equal-cols">
                        <div class="col-lg-8 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">Configuración CRM</h1>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>Configuración CRM</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6 col-xs-12 col-botones text-center">
                          <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                        </div>
                    </div>
                </div>

                <div class="block full break-padding">

                    <div class="block-title">
                        <ul class="nav nav-tabs" data-toggle="tabs">
                            <li class="active"><a href="#tab-whatsapp">WhatsApp</a></li>
                            <li><a href="#tab-correo">Correo</a></li>
                        </ul>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane active" id="tab-whatsapp">
                            <div class="row">
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2><i class="fa fa-whatsapp"></i> Cuentas Whatsapp Business</h2></div>
                                        <ul class="list-group" style="margin:-20px;margin-bottom:0;max-height:60vh;overflow:auto;">
                                            <li class="list-group-item" *ngFor="let item of data.cuentas_whatsapp;let i = index">
                                                <a class="btn btn-danger btn-xs absolute" style="top: 10px; right: 15px;" (click)="DelCuentaWhatsapp(item)"><i class="fa fa-trash"></i></a>

                                                <b class="font-18">{{item.nombre}}</b><br>
                                                <div class="m-t-10 m-b-10">
                                                    Total Conver: <b class="text-primary font-16 m-r-20">{{item.total_conversaciones}}</b>
                                                    Gasto Aprox: <b class="text-primary font-16">{{globals.FloatES(item.gasto)}} €</b>
                                                </div>

                                                <ul class="list-group m-t-10">
                                                    <li class="list-group-item">
                                                        <span class="inline-block text-primary" style="width:200px">Facebook ID (Número):</span>
                                                        <span contenteditable="true" class="editable" (keydown)="KeyFB('whatsapp_fbid', $event, item)" (blur)="ValFB('whatsapp_fbid', $event, item)" [innerHTML]="item.whatsapp_fbid"></span>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <span class="inline-block text-primary" style="width:200px">Facebook ID (Cuenta Business):</span>
                                                        <span contenteditable="true" class="editable" (keydown)="KeyFB('whatsapp_fbid_account', $event, item)" (blur)="ValFB('whatsapp_fbid_account', $event, item)" [innerHTML]="item.whatsapp_fbid_account"></span>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <span class="inline-block text-primary" style="width:200px">Token:</span>
                                                        <small style="word-break: break-all;">
                                                            <span contenteditable="true" class="editable" (keydown)="KeyFB('whatsapp_access_token', $event, item)" (blur)="ValFB('whatsapp_access_token', $event, item)" [innerHTML]="item.whatsapp_access_token"></span>
                                                        </small>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <span class="inline-block text-primary" style="width:200px">Plantilla generica:</span>
                                                        <span contenteditable="true" class="editable" (keydown)="KeyFB('whatsapp_plantilla_generica', $event, item)" (blur)="ValFB('whatsapp_plantilla_generica', $event, item)" [innerHTML]="item.whatsapp_plantilla_generica"></span>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <span class="inline-block text-primary" style="width:200px">Cuota mensual:</span>
                                                        <span contenteditable="true" class="editable" (keydown)="KeyFB('whatsapp_cuota', $event, item)" (blur)="ValFB('whatsapp_cuota', $event, item)" [innerHTML]="item.whatsapp_cuota"></span>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <span class="inline-block text-primary" style="width:200px">Pago desde:</span>
                                                        <span contenteditable="true" class="editable" (keydown)="KeyFB('whatsapp_pago_desde', $event, item)" (blur)="ValFB('whatsapp_pago_desde', $event, item)" [innerHTML]="item.whatsapp_pago_desde"></span>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <span class="inline-block text-primary" style="width:200px">Teléfono avisos:</span>
                                                        <span contenteditable="true" class="editable" (keydown)="KeyFB('whatsapp_telefono_avisos', $event, item)" (blur)="ValFB('whatsapp_telefono_avisos', $event, item)" [innerHTML]="item.whatsapp_telefono_avisos"></span>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <span class="inline-block text-primary" style="width:200px">Email avisos:</span>
                                                        <span contenteditable="true" class="editable" (keydown)="KeyFB('whatsapp_email_avisos', $event, item)" (blur)="ValFB('whatsapp_email_avisos', $event, item)" [innerHTML]="item.whatsapp_email_avisos"></span>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2><i class="fa fa-whatsapp"></i> Añadir cuenta</h2></div>
                                        <div class="form-group">
                                            <label>Nombre de la cuenta</label>
                                            <input type="text" class="form-control" id="txtfbnombre" maxlength="50">
                                        </div>
                                        <div class="form-group">
                                            <label>Facebook ID (Número)</label>
                                            <input type="text" class="form-control" id="txtfbid" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Facebook ID (Cuenta Business)</label>
                                            <input type="text" class="form-control" id="txtfbidaccount" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Facebook Access Token</label>
                                            <input type="text" class="form-control" id="txtfbtoken" maxlength="400">
                                        </div>
                                        <div class="form-group">
                                            <label>Plantilla generica</label>
                                            <input type="text" class="form-control" id="txtfbplantilla" maxlength="50" value="generica">
                                        </div>
                                        <div class="form-group">
                                            <label>Cuota mensual</label>
                                            <input type="number" class="form-control" id="txtfbcuota" maxlength="20">
                                        </div>
                                        <div class="form-group">
                                            <label>Pago desde</label>
                                            <input type="datetime-local" class="form-control" id="txtfbpagodesde" maxlength="20">
                                        </div>
                                        <div class="form-group">
                                            <label>Teléfono avisos</label>
                                            <input type="tel" class="form-control" id="txtfbtelefono" maxlength="20">
                                        </div>
                                        <div class="form-group">
                                            <label>Email avisos</label>
                                            <input type="tel" class="form-control" id="txtemailavisos" maxlength="20">
                                        </div>
                                        <div class="text-right m-b-20">
                                            <a id="btnaddcuenta" class="btn btn-success" (click)="AddCuentaWhatsapp()">Añadir cuenta</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="tab-correo">
                            <div class="row">
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2><i class="fa fa-envelope-o"></i> Correo salida</h2></div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Host</label>
                                                    <input type="text" class="form-control" [(ngModel)]="data.emailconf_host" maxlength="80">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Port</label>
                                                    <input type="number" class="form-control" [(ngModel)]="data.emailconf_port" maxlength="12">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>SSL</label>
                                                    <select class="form-control" [(ngModel)]="data.emailconf_ssl">
                                                    <option value="1">Sí</option>
                                                    <option value="0" selected>No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Email para env&iacute;os</label>
                                            <input type="email" class="form-control" [(ngModel)]="data.emailconf_email" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Contraseña de email para env&iacute;os</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.emailconf_pass" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Alias de email para env&iacute;os</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.emailconf_alias" maxlength="80">
                                        </div>
                                        <div>
                                            <a class="btn btn-default btn-block m-b-20" (click)="EmailTest()"><i class="icofont-envelope"></i> Enviar Test</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div class="col-botones">
                  <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>