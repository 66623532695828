<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">Seguimiento de servidores</h1>
                                <button class="btn btn-xs btn-default middle m-l-10" (click)="CargarDatos()"><i class="fa fa-refresh"></i></button>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>Seguimiento de servidores</li>
                            </ul>
                        </div>
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto text-center">
                          <div class="input-group width-100">
                            <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                            <div class="input-group-append cursor" (click)="Buscar()">
                              <span class="input-group-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-xs-12 my-auto text-center">
                          <a class="btn btn-default btn-primary btn-block" (click)="Nuevo()"><i class="fa fa-plus"></i> Nuevo seguimiento</a>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-6" *ngFor="let item of data">
                        
                        <div class="block">
                            <div class="block-title flex" style="align-items: center;">
                                <h2>{{item.nombre}}</h2>
                                <a class="btn btn-xs btn-primary m-l-auto" (click)="Editar(item)" style="height:24px;"><i class="fa fa-pencil"></i></a>
                                <a class="btn btn-xs btn-danger m-l-5 m-r-5" (click)="Borrar(item)" style="height:24px;"><i class="fa fa-trash"></i></a>
                            </div>
                            <div class="form-group">
                                <label>Url / IP</label><br>
                                <a href="https://{{item.url}}" target="_blank">{{item.url}}</a>
                            </div>
                            <ul class="list-group" *ngIf="item.services">
                                <li class="list-group-item flex" *ngFor="let service of item.services;let i = index" style="align-items: center;">
                                    <span>{{service.service}}</span>
                                    <span class="m-l-auto text-success" *ngIf="service.status == 'online'">{{service.status}}</span>
                                    <span class="m-l-auto text-danger" *ngIf="service.status == 'offline'">{{service.status}}</span>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
                <hr>
                <div class="text-center">
                    <a class="btn btn-default btn-primary" (click)="Nuevo()"><i class="fa fa-plus"></i> Añadir seguimiento</a>
                </div>
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>

<div class="modal fade" id="modal-edit-seguimiento" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title"><i class="fa fa-server"></i> Nuevo seguimiento</h4>
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
        </div>
        <div class="modal-body">
            <div class="form-group row">
                <label class="col-lg-3 col-form-label">Nombre</label>
                <div class="col-lg-9">
                    <input type="text" class="form-control" [(ngModel)]="dataitem.nombre" maxlength="100">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-3 col-form-label">Url / IP</label>
                <div class="col-lg-9">
                    <input type="url" class="form-control" [(ngModel)]="dataitem.url" maxlength="400">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-3 col-form-label">Activo</label>
                <div class="col-lg-9">
                    <select class="form-control" [(ngModel)]="dataitem.activo" style="width: auto;">
                        <option value="1">Sí</option>
                        <option value="0">No</option>
                    </select>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button id="btnGuardarModal" type="button" class="btn btn-primary" (click)="GuardarModal()">Guardar</button>
        </div>
        </div>
    </div>
</div>