import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './backend/login/login.component';
import { DashboardComponent } from './backend/dashboard/dashboard.component';
import { UsuariosComponent } from './backend/usuarios/usuarios.component';
import { LicenciasComponent } from './backend/licencias/licencias.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Globals } from './classes/globals.class';
import { MenuComponent } from './backend/controls/menu/menu.component';
import { HeaderComponent } from './backend/controls/header/header.component';
import { EmailBuilderComponent } from './backend/email-builder/email-builder.component';
import { CRMClientesComponent } from './backend/crm/clientes/clientes.component';
import { CRMConfigComponent } from './backend/crm/config/config.component';
import { CRMCampanasComponent } from './backend/crm/campanas/campanas.component';
import { CRMPlantillasComponent } from './backend/crm/plantillas/plantillas.component';
import { CRMEditPlantillaComponent } from './backend/crm/plantillas/edit-plantilla/edit-plantilla.component';
import { CRMEditClienteComponent } from './backend/crm/clientes/edit-cliente/edit-cliente.component';
import { CRMEditCampanaComponent } from './backend/crm/campanas/edit-campana/edit-campana.component';
import { CRMMensajesComponent } from './backend/crm/mensajes/mensajes.component';
import { WhatsappWebComponent } from './frontend/whatsapp-web/whatsapp-web.component';
import { SeguimientoServidoresComponent } from './backend/seguimiento-servidores/seguimiento-servidores.component';
import { EditUsuarioComponent } from './backend/usuarios/edit-usuario/edit-usuario.component';
import { ConfigAppComponent } from './backend/config-app/config-app.component';
import { GeneradorLogsAccesoComponent } from './backend/generador-logs-acceso/generador-logs-acceso.component';
import { GeneradorDNSComponent } from './backend/generador-dns/generador-dns.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    MenuComponent,
    HeaderComponent,
    ConfigAppComponent,
    UsuariosComponent,
    LicenciasComponent,
    EmailBuilderComponent,
    CRMClientesComponent,
    CRMConfigComponent,
    CRMCampanasComponent,
    CRMPlantillasComponent,
    CRMEditPlantillaComponent,
    CRMEditClienteComponent,
    CRMEditCampanaComponent,
    CRMMensajesComponent,
    WhatsappWebComponent,
    SeguimientoServidoresComponent,
    EditUsuarioComponent,
    GeneradorLogsAccesoComponent,
    GeneradorDNSComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
  ],
  providers: [Globals],
  bootstrap: [AppComponent]
})
export class AppModule { }
