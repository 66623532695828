<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row-equal-cols">
                        <div class="col-lg-8 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">Configuración App</h1>
                                <!-- <button class="btn btn-xs btn-default middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button> -->
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>Configuración</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6 col-xs-12 col-botones text-center">
                          <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                        </div>
                    </div>
                </div>

                <div class="block full break-padding">
                    <div class="block-title">
                        <!-- <div class="block-options pull-right">
                            <a href="javascript:void(0)" class="btn btn-effect-ripple btn-default" data-toggle="tooltip" title=""
                                style="overflow: hidden; position: relative;" data-original-title="Settings"><i
                                    class="fa fa-cog"></i></a>
                        </div> -->
                        <ul class="nav nav-tabs" data-toggle="tabs">
                            <li class="active"><a href="#tab-correo"><i class="icofont-envelope"></i> Correo</a></li>
                            <li class=""><a href="#tab-db"><i class="icofont-database"></i> Base de datos</a></li>
                            <li class=""><a href="#tab-logs"><i class="fa fa-list"></i> Logs</a></li>
                            <li class=""><a href="#tab-registro-ips"><i class="fa fa-server"></i> Registro de IPs</a></li>
                        </ul>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane active" id="tab-correo">
                            <div class="row">
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2>Correo salida</h2></div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Host</label>
                                                    <input type="text" class="form-control" [(ngModel)]="data.emailconf_host" maxlength="80">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Port</label>
                                                    <input type="number" class="form-control" [(ngModel)]="data.emailconf_port" maxlength="12">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>SSL</label>
                                                    <select class="form-control" [(ngModel)]="data.emailconf_ssl">
                                                    <option value="1">Sí</option>
                                                    <option value="0" selected>No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Email para env&iacute;os</label>
                                            <input type="email" class="form-control" [(ngModel)]="data.emailconf_email" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Contraseña de email para env&iacute;os</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.emailconf_pass" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Alias de email para env&iacute;os</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.emailconf_alias" maxlength="80">
                                        </div>
                                        <hr>
                                        <div class="form-group">
                                            <label>T&iacute;tulo cabecera email</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.emailconf_titulo_email" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>URL imagen logo</label>
                                            <input type="url" class="form-control" [(ngModel)]="data.emailconf_logo" maxlength="400">
                                        </div>
                                        <div class="form-group">
                                            <label>Color BG Cabecera</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.emailconf_bgcolor_cabecera" maxlength="20">
                                        </div>
                                        <div class="form-group">
                                            <label>Color texto destacado</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.emailconf_color_destacado" maxlength="20">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2>Correo administrador</h2></div>
                                        <div class="form-group">
                                            <label>Alias email administrador</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.emailconf_emailadmin_alias" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Email administrador 1</label>
                                            <input type="email" class="form-control" [(ngModel)]="data.emailconf_emailadmin1" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Email administrador 2</label>
                                            <input type="email" class="form-control" [(ngModel)]="data.emailconf_emailadmin2" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Email administrador 3</label>
                                            <input type="email" class="form-control" [(ngModel)]="data.emailconf_emailadmin3" maxlength="80">
                                        </div>
                                    </div>
                                    <div>
                                        <a class="btn btn-default btn-block m-b-20" (click)="EmailTest()"><i class="icofont-envelope"></i> Enviar Test</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="tab-pago">
                            <div class="block">
                                <div class="block-title"><h2>Formas de pago</h2></div>
                                <div class="row m-b-10" *ngFor="let item of data.formas_pago">
                                    <div class="col-md-3 col-xs-12">
                                        <div class="font-bold">{{item.forma_pago}}</div>
                                    </div>
                                    <div class="col-md-3 col-xs-12">
                                        {{item.slug}}
                                    </div>
                                    <div class="col-md-6 col-xs-12">
                                        <textarea type="text" class="form-control text-editor" 
                                        placeholder="Introduce aquí el texto que se incluirá en el email" [(ngModel)]="item.descripcion_email" rows="4"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="tab-db">
                            <!-- <div class="row">
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2>Exportar Base Datos</h2></div>
                                        <a class="btn btn-default btn-block m-b-20" (click)="ExportarDB()"><i class="icofont-database"></i> Exportar</a>
                                    </div>
                                </div>
                            </div>
                            <hr> -->
                            <label>Logs DB <a class="btn btn-default m-l-10" (click)="CargarLogs()"><i class="fa fa-refresh"></i></a><a class="btn btn-default m-l-10" (click)="BorrarLogs()"><i class="fa fa-trash"></i></a></label>
                            <div class="logs" [innerHTML]="data.logs_db"></div>
                        </div>
                        <div class="tab-pane" id="tab-logs">

                            <div class="row">
                                <div class="col-xs-12 col-md-6">
                                    <label>Logs generales <a class="btn btn-default m-l-10" (click)="CargarLogs()"><i class="fa fa-refresh"></i></a></label>
                                    <div class="logs">
                                        <div class="m-b-10" *ngFor="let item of data.logs">
                                            Fecha: {{item.fecha}}<br>
                                            Log: {{item.log}}<br>
                                            Excepción: {{item.exception}}<br>
                                            Cliente: {{item.cliente}}<br>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <label>CRM Logs <a class="btn btn-default m-l-10" (click)="CargarLogs()"><i class="fa fa-refresh"></i></a></label>
                                    <div class="logs">
                                        <div class="m-b-10" *ngFor="let item of data.crm_logs">
                                            Fecha: {{item.fecha}}
                                            &nbsp;&nbsp;Destinatario: {{item.destinatario}}<br>
                                            Estado: {{item.estado}}
                                            &nbsp;&nbsp;Tipo: {{item.tipo}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="tab-pane" id="tab-registro-ips">

                            <div class="row" style="display: flex;flex-wrap: wrap;margin-bottom: 1rem;">
                                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <h4 class="middle">
                                        Registro de ips
                                        <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarRegistroIPs()"><i class="fa fa-refresh"></i></button>
                                    </h4>
                                </div>
                                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-center no-wrap">
                                <label class="col-form-label inline-block m-r-10">Mostrar</label>
                                <select class="form-control inline-block" (change)="Buscar()" [(ngModel)]="params_registro_ips.page_size" style="width:auto;">
                                    <option>25</option>
                                    <option>50</option>
                                    <option>100</option>
                                    <option>200</option>
                                </select>
                                </div>
                                <div class="col-lg-7 col-md-4 col-sm-6 col-xs-12 text-center">
                                <div class="input-group width-100">
                                    <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params_registro_ips.filtro">
                                    <div class="input-group-append cursor" (click)="Buscar()" style="width: 4%;">
                                    <span class="input-group-addon"><i class="fa fa-search"></i></span>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <!-- Table Styles Content -->
                            <div class="table-responsive table-grid" style="margin-bottom: 100px;">
                                <table class="table table-striped table-bordered table-vcenter table-hover">
                                    <thead>
                                        <tr>
                                            <th order="ip">IP</th>
                                            <th style="width:130px" order="fecha">Fecha</th>
                                            <th order="dispositivo">Dispositivo</th>
                                            <th order="plugin">Plugin</th>
                                            <th>Servidor</th>
                                            <!-- <th style="width:50px">Borrar</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of registro_ips" class="cursor">
                                            <td>
                                                <div class="font-bold">IP: {{item.ip}}</div>
                                                <div class="font-bold">Server IP: {{item.server_ip}}</div>
                                                <div class="text-primary font-italic" *ngIf="item.nombre">{{item.nombre}}</div>
                                                <div class="text-primary" *ngIf="item.email">{{item.email}}</div>
                                            </td>
                                            <td>{{item.fecha_format}}</td>
                                            <td>{{item.dispositivo}}</td>
                                            <td>{{item.plugin}}</td>
                                            <td class="breaktd">
                                                <a [href]="item.url" target="_blank" *ngIf="item.url"><i class="fa fa-link"></i> {{item.url}}</a>
                                                <div *ngIf="item.version_php">PHP: <span>{{item.version_php}}</span></div>
                                                <div *ngIf="item.version_wp">WordPress: <span>{{item.version_wp}}</span></div>
                                                <div *ngIf="item.version_wc">WooCommerce: <span>{{item.version_wc}}</span></div>
                                                <hr *ngIf="item.datos" style="margin: 4px 0;">
                                                <div *ngIf="item.datos" [innerHTML]="item.datos"></div>
                                            </td>
                                            <!-- <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="Borrar(item)"><i class="fa fa-trash text-white"></i></a></td> -->
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="dataTables_paginate paging_simple_numbers m-r-10">
                                <ul class="pagination justify-content-end">
                                    <li class="paginate_button page-item previous {{params_registro_ips.page_index <= 1 ? 'disabled' : ''}}">
                                    <a (click)="Paging('prev')" class="page-link">Anterior</a>
                                    </li>
                                    <li class="paginate_button page-item" *ngIf="params_registro_ips.page_index > 1">
                                    <a (click)="Paging('prev')" class="page-link">{{params_registro_ips.page_index-1}}</a>
                                    </li>
                                    <li class="paginate_button page-item active">
                                    <a class="page-link cursor">{{params_registro_ips.page_index}}</a>
                                    </li>
                                    <li class="paginate_button page-item" *ngIf="params_registro_ips.page_index < params_registro_ips.pages">
                                    <a (click)="Paging('next')" class="page-link">{{params_registro_ips.page_index+1}}</a>
                                    </li>
                                    <li class="paginate_button page-item next {{params_registro_ips.page_index >= params_registro_ips.pages ? 'disabled' : ''}}">
                                    <a (click)="Paging('next')" class="page-link">Siguiente</a>
                                    </li>
                                </ul>
                                </div>
                            </div>

                        </div>
                    </div>
        
                    <div class="col-botones">
                    <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                    </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>