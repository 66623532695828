import { ApplicationRef, Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let toastr: any;
declare let EmojiPicker: any;

@Component({
  selector: 'app-whatsapp-web',
  templateUrl: './whatsapp-web.component.html',
  styleUrls: ['./whatsapp-web.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WhatsappWebComponent implements OnInit {
  public token_admin:string = (this.globals.GetQueryString('token_admin') ? this.globals.GetQueryString('token_admin') : null);
  public token:string = (this.globals.GetQueryString('token') ? this.globals.GetQueryString('token') : '');
  public fbid:string = (this.globals.GetQueryString('fbid') ? this.globals.GetQueryString('fbid') : '');
  public secret:string = 'DX9FO2BV46QYVD53JQ02';
  public config:any = {};
  public contactos:any = [];
  public mensajes:any = [];
  public contacto:any = null;
  public cuenta:any = null;
  public movil:boolean = false;
  public vista:string = '';
  public buscando:boolean = false;
  public busqueda:string = '';
  public buscando_mensaje:boolean = false;
  public busqueda_mensaje:string = '';

  constructor(
    public title: Title,
    public meta: Meta,
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Mensajes');
    if (!this.token || this.token == '') {
      this.ngZone.run(() => this.router.navigateByUrl('/login')).then();
      return;
    }
    if (this.token != this.secret) {
      this.ngZone.run(() => this.router.navigateByUrl('/login')).then();
      return;
    }
    App.init();
    $.disableinputnumberwheel();
    this.CargarConfig();
    clearTimeout($.idtimecontactos);
    clearTimeout($.idtimemensajes);
    window.addEventListener("beforeunload", (event) => {
      event.preventDefault();
      clearTimeout($.idtimecontactos);
      clearTimeout($.idtimemensajes);
      return event;
    });
    $.emojiPicker = new EmojiPicker({
      emojiable_selector: '[data-emojiable=true]',
      assetsPath: '/assets/emoji-picker/img/',
      popupButtonClasses: 'fa fa-smile-o'
    });
    $.emojiPicker.discover();
    let that = this;
    $('.emoji-wysiwyg-editor').on('keyup', (e:any) => {
      if (e.key === "Enter") {
        e.preventDefault();
        let enter = $('#chk-enviar-enter').prop('checked');
        if (enter) that.Enviar();
      }
    });
    setTimeout(() => {
      window.parent.postMessage({
        url: window.location.href,
        height: $('#whatsapp-container').height()
      }, '*');
      window.postMessage({
        url: window.location.href,
        height: $('#whatsapp-container').height()
      }, '*');
    }, 200);
    // window.addEventListener('resize', function() {
    //   setTimeout(() => {
    //     window.parent.postMessage({
    //       url: window.location.href,
    //       height: $('#whatsapp-container').height()
    //     }, '*');
    //     window.postMessage({
    //       url: window.location.href,
    //       height: $('#whatsapp-container').height()
    //     }, '*');
    //   }, 100);
    // });
    if ($(window).width() <= 768) {
      this.movil = true;
      this.vista = 'contactos';
      // let height = $(window).height() - ($(window).height()/4.5);
      // $('.whatsapp-contactos-container').css('max-height', height+'px');
      // height = $(window).height() - ($(window).height()/2.6);
      // $('.whatsapp-chat-container').css('max-height', height+'px');
    }
  }

  CargarConfig() {
    if (!this.fbid || this.fbid == '') {
      this.globals.Api('/crm-config').subscribe((data:any) => {
        if (!data) return;
        this.config = data;
        if (this.config.cuentas_whatsapp && this.config.cuentas_whatsapp.length > 1) {
          this.cuenta = this.config.cuentas_whatsapp[0];
        }
        this.CargarContactos();
      });
    } else {
      this.globals.Api('/crm-cuentas', {fbid: this.fbid}).subscribe((data:any) => {
        if (!data || !data.length) return;
        this.cuenta = data[0];
        this.CargarContactos();
      });
    }
  }

  Refrescar() {
    clearTimeout($.idtimecontactos);
    clearTimeout($.idtimemensajes);
    this.CargarContactos();
  }
  ChangeCuenta(event:any) {
    clearTimeout($.idtimecontactos);
    clearTimeout($.idtimemensajes);
    this.cuenta = this.config.cuentas_whatsapp.find((el:any) => el.id == event.target.value);
    this.mensajes = [];
    this.contactos = [];
    this.contacto = null;
    this.CargarContactos();
  }
  Buscar() {
    clearTimeout($.idbusqueda);
    $.idbusqueda = setTimeout(() => {
      this.CargarContactos();
    }, 300);
  }
  CancelarBusqueda() {
    this.busqueda = '';
    this.buscando = false;
    this.CargarContactos();
  }
  BuscarConversacion() {
    clearTimeout($.idbusqueda_conversacion);
    $.idbusqueda_conversacion = setTimeout(() => {
      this.CargarMensajes();
    }, 300);
  }
  CancelarBusquedaConversacion() {
    this.busqueda_mensaje = '';
    this.buscando_mensaje = false;
    this.CargarMensajes();
  }
  CargarContactos() {
    clearTimeout($.idtimecontactos);
    let active = this.contactos.find((el:any) => el.active == true);
    this.globals.Api('/crm-mensajes', {fbid: this.cuenta.whatsapp_fbid, token_admin: this.token_admin, conversaciones: true, filtro: this.busqueda}).subscribe((data:any) => {
      if (!data || !data.length) return;
      this.contactos = data;
      if (active) {
        let contacto = this.contactos.find((el:any) => el.whatsapp_fbid == active.whatsapp_fbid && el.numero == active.numero);
        if (contacto) contacto.active = true;
      } else {
        // if (!this.contacto && this.contactos.length && !this.movil) this.SeleccionarContacto(this.contactos[0]);
      }
      $.idtimecontactos = setTimeout(() => {this.CargarContactos();}, 5000);
    }, (error:any) => {
      $.idtimecontactos = setTimeout(() => {this.CargarContactos();}, 5000);
    });
  }
  BuscarContacto(event:any) {
    let value = event.target.value;
    if (!value || value == '' || value.toString().trim() == '') return;
    if (value.length < 3) return;
    clearTimeout($.TIMEBuscarContacto);
    $.TIMEBuscarContacto = setTimeout(() => {
      
    }, 300);
  }
  SeleccionarContacto(item:any) {
    for (let el of this.contactos) el.active = null;
    item.active = true;
    item.total_noleidos = 0;
    this.contacto = item;
    this.CargarMensajes(true);
    $('#txtmensaje').val('');
    $('.emoji-wysiwyg-editor').text('');
    if (this.movil) this.vista = 'chat';
  }
  Urlify(string:string) {
    const urls = string.match(/((((ftp|https?):\/\/)|(w{3}\.))[\-\w@:%_\+.~#?,&\/\/=]+)/g);
    if (urls) {
      urls.forEach(function (url) {
        if (url.toLowerCase().indexOf('uploads') != -1) return;
        string = string.replace(url, '<a target="_blank" href="' + url + '">' + url + "</a>");
      });
    }
    return string.replace("(", "<br/>(");
  }
  CargarMensajes(scroll:boolean = false) {
    clearTimeout($.idtimemensajes);
    let isbottom = ($('.whatsapp-chat-container').length && ($('.whatsapp-chat-container').scrollTop() + $('.whatsapp-chat-container').innerHeight()) >= $('.whatsapp-chat-container')[0].scrollHeight - 20 ? true : false);
    this.globals.Api('/crm-mensajes', {fbid: this.contacto.whatsapp_fbid, token_admin: this.token_admin, numero: this.contacto.numero, filtro: this.busqueda_mensaje}).subscribe((data:any) => {
      if (!data || !data.length) return;
      for (let item of data) item.mensaje = this.Urlify(item.mensaje);
      if (JSON.stringify(data) == JSON.stringify(this.mensajes)) {
        $.idtimemensajes = setTimeout(() => {this.CargarMensajes();}, 5000);
        return;
      }
      this.mensajes = data;
      this.appref.tick();
      if (isbottom || scroll) {
        setTimeout(() => { $('.whatsapp-chat-container').scrollTop(
          $('.whatsapp-chat-container').prop('scrollHeight')
        ); }, 100);
      }
      console.log('load mensajes');
      $.idtimemensajes = setTimeout(() => {this.CargarMensajes();}, 5000);
      $('.link-img-principal').magnificPopup({ type: "image", image: { titleSrc: "title" } });
      setTimeout(() => {
        window.parent.postMessage({
          url: window.location.href,
          height: $('#whatsapp-container').height()
        }, '*');
        window.postMessage({
          url: window.location.href,
          height: $('#whatsapp-container').height()
        }, '*');
      }, 100);
    }, (error:any) => {
      $.idtimemensajes = setTimeout(() => {this.CargarMensajes();}, 5000);
    });
  }
  Enviar() {
    let mensaje = $('#txtmensaje').val().trim();
    if (!mensaje || mensaje == '') return;
    $.buttonloading('btnenviar', '<i class="fa fa-send"></i>');
    this.globals.Api('/crm-mensaje-add', {
      fbid: this.contacto.whatsapp_fbid,
      numero: this.contacto.numero,
      mensaje: mensaje
    }).subscribe((data:any) => {
      $.buttoncancelloading('btnenviar');
      if (!data || data.error) {
        toastr.error(`No se ha podido enviar tu mensaje`, 'Error');
        return;
      }
      $('#txtmensaje').val('');
      $('.emoji-wysiwyg-editor').text('');
      this.CargarMensajes(true);
      setTimeout(() => { $('.whatsapp-chat-container').scrollTop($('.whatsapp-chat-container').height() + 50); }, 100);
    }, (error:any) => {
      $.buttoncancelloading('btnenviar');
      toastr.error(`No se ha podido enviar tu mensaje`, 'Error');
    });
  }
  DelConversacion() {
    swal({
      title: "¿Borrar conversación?",
      text: '¡No se podrán deshacer los cambios!',
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/crm-mensajes-del', {fbid: this.contacto.whatsapp_fbid, numero: this.contacto.numero}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.contacto = null;
        this.mensajes = [];
        this.CargarContactos();
        this.appref.tick();
      });
    });
  }
  Atras() {
    clearTimeout($.idtimemensajes);
    this.vista = 'contactos';
    for (let el of this.contactos) el.active = null;
    this.contacto = null;
  }
}
