<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-xs-12 my-auto">
                            <div class="header-section">
                                <h1 class="middle">Usuarios</h1>
                                <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/']">Inicio</a></li>
                                <li>Usuarios</li>
                            </ul>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center no-wrap">
                          <label class="col-form-label inline-block m-r-10">Mostrar</label>
                          <select class="form-control inline-block" (change)="Buscar()" [(ngModel)]="params.page_size" style="width:auto;">
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option>200</option>
                          </select>
                        </div>
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto text-center">
                          <div class="input-group width-100">
                            <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                            <div class="input-group-append cursor" (click)="Buscar()">
                              <span class="input-group-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center">
                          <a class="btn btn-default btn-primary btn-block" (click)="Nuevo()"><i class="fa fa-plus"></i> Nuevo usuario</a>
                        </div>
                    </div>
                </div>
                <!-- Table Styles Content -->
                <div class="table-responsive table-grid">
                    <!--
                    Available Table Classes:
                        'table'             - basic table
                        'table-bordered'    - table with full borders
                        'table-borderless'  - table with no borders
                        'table-striped'     - striped table
                        'table-condensed'   - table with smaller top and bottom cell padding
                        'table-hover'       - rows highlighted on mouse hover
                        'table-vcenter'     - middle align content vertically
                    -->
                    <table class="table table-striped table-bordered table-vcenter table-hover">
                        <thead>
                            <tr>
                              <!-- <th style="width:50px;" class="text-center"><label class="csscheckbox csscheckbox-primary"><input type="checkbox"><span></span></label></th> -->
                              <th></th>
                              <th style="width:200px" order="nombre">Nombre</th>
                              <th style="width:130px" order="alta">Alta</th>
                              <th order="rol">Rol</th>
                              <th order="localidad">Localidad</th>
                              <th order="telefono">Tel&eacute;fono</th>
                              <th order="email">Email</th>
                              <th style="width:50px">Acceso</th>
                              <th style="width:50px">Editar</th>
                              <th style="width:50px">Borrar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of usuarios" class="cursor"
                              class="cursor {{(item.caducado ? 'bg-tr-caducado': '')}}">
                              <!-- <td class="text-center"><label class="csscheckbox csscheckbox-primary"><input type="checkbox"><span></span></label></td> -->
                              <td class="text-center">
                                <img *ngIf="item.imagen" [src]="item.imagen" class="img-usuario img-circle square">
                                <i *ngIf="!item.imagen || item.imagen == ''" class="fa fa-user fa-2x"></i>
                              </td>
                              <td (click)="Editar(item)">
                                <div class="font-bold text-primary">{{item.nombre}} {{item.apellidos}}</div>
                                <small *ngIf="item.empresa" class="text-secondary font-italic">{{item.empresa}}</small>
                              </td>
                              <td>{{item.alta_format}}</td>
                              <td>{{item.rol}}</td>
                              <td>{{item.localidad}}</td>
                              <td><span *ngIf="item.telefono" class="no-wrap"><i class="fa fa-phone"></i> {{item.telefono}}</span></td>
                              <td class="breaktd"><span *ngIf="item.email" class="no-wrap"><i class="fa fa-envelope"></i> {{item.email}}</span></td>
                              <td class="text-center cursor btntd" (click)="CambiarAcceso(item)">
                                <a *ngIf="item.acceso"><i class="fa fa-check text-success"></i></a>
                                <a *ngIf="!item.acceso"><i class="fa fa-close text-danger"></i></a>
                              </td>
                              <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="Editar(item)"><i class="fa fa-pencil text-white"></i></a></td>
                              <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="Borrar(item)"><i class="fa fa-trash text-white"></i></a></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="dataTables_paginate paging_simple_numbers m-r-10">
                      <ul class="pagination justify-content-end">
                        <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}">
                          <a (click)="Paging('prev')" class="page-link">Anterior</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index > 1">
                          <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
                        </li>
                        <li class="paginate_button page-item active">
                          <a class="page-link cursor">{{params.page_index}}</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
                          <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
                        </li>
                        <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}">
                          <a (click)="Paging('next')" class="page-link">Siguiente</a>
                        </li>
                      </ul>
                    </div>
                </div>
                
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>