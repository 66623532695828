import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;

@Component({
  selector: 'app-crm-config',
  templateUrl: './config.component.html'
})
export class CRMConfigComponent implements OnInit {
  public data:any = {};

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(1);
    $.checkrolredirect(2);
    $.checkrolredirect(3);
    $.checkrolredirect(4);
    this.CargarConfig();
  }

  CargarConfig() {
    this.globals.Api('/crm-config').subscribe((data:any) => {
      if (!data) return;
      this.data = data;
    });
  }

  Guardar() {
    $.buttonloading('.btn-guardar', 'Enviando');
    this.globals.Api('/crm-config-edit', this.data).subscribe((data:any) => {
      $.buttoncancelloading('.btn-guardar');
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      swal('Guardado', 'Datos guardados correctamente', 'success');
    }, error => {
      $.buttoncancelloading('.btn-guardar');
    });
  }

  EmailTest() {
    this.globals.Api('/crm-email-test').subscribe((data:any) => {
      if (!data || data.value != 'OK') {
        swal('Error', data.value, 'error');
        return;
      }
      swal('Enviado', 'Intento de email enviado al servidor', 'info');
    }, error =>  {
      swal('Error', 'Se ha producido un error', 'error');
    });
  }

  AddCuentaWhatsapp() {
    if ($('#txtfbnombre').val().trim() == '') {
      swal('Complete campos', 'Por favor, introduce el nombre de la cuenta para continuar', 'error');
      return;
    }
    if ($('#txtfbid').val().trim() == '') {
      swal('Complete campos', 'Por favor, introduce el ID de Facebook para continuar', 'error');
      return;
    }
    if ($('#txtfbtoken').val().trim() == '') {
      swal('Complete campos', 'Por favor, introduce el token de Facebook para continuar', 'error');
      return;
    }
    if (!this.data.cuentas_whatsapp) this.data.cuentas_whatsapp = [];
    this.data.cuentas_whatsapp.push({
      id_config: 1, id: this.globals.GenerateRandomString(5), nombre: $('#txtfbnombre').val().trim(),
      whatsapp_fbid: $('#txtfbid').val().trim(), whatsapp_access_token: $('#txtfbtoken').val().trim(),
      whatsapp_fbid_account: $('#txtfbidaccount').val().trim(), whatsapp_plantilla_generica: $('#txtfbplantilla').val().trim(),
      whatsapp_cuota: ($('#txtfbcuota').val().trim() != '' ? $('#txtfbcuota').val().trim() : null),
      whatsapp_pago_desde: ($('#txtfbpagodesde').val().trim() != '' ? $('#txtfbpagodesde').val().trim() : null),
      whatsapp_telefono_avisos: ($('#txtfbtelefono').val().trim() != '' ? $('#txtfbtelefono').val().trim() : null),
      whatsapp_email_avisos: ($('#txtemailavisos').val().trim() != '' ? $('#txtemailavisos').val().trim() : null)
    });
    $.buttonloading('btnaddcuenta', 'Enviando');
    this.globals.Api('/crm-config-edit', this.data).subscribe((data:any) => {
      $.buttoncancelloading('btnaddcuenta');
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      $('#txtfbnombre').val('');
      $('#txtfbid').val('');
      $('#txtfbtoken').val('');
      $('#txtfbcuota').val('');
      $('#txtfbpagodesde').val('');
      $('#txtfbtelefono').val('');
      $('#txtemailavisos').val('');
      swal('Guardado', 'Datos guardados correctamente', 'success');
      this.CargarConfig();
    }, error => {
      $.buttoncancelloading('btnaddcuenta');
    });
  }
  DelCuentaWhatsapp(item:any) {
    swal({
      title: "¿Borrar cuenta?",
      text: '¡No se podrán deshacer los cambios!',
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/crm-config-cuenta-whatsapp-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.cuentas_whatsapp.splice(this.data.cuentas_whatsapp.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }

  KeyFB(campo:string, event:any, item:any) {
    if (event.which == 13 || event.keyCode == 13) {
      if (campo != 'whatsapp_cuota' && campo != 'whatsapp_pago_desde' &&
        (!event.target.textContent || event.target.textContent.trim() == '')) {
        return false;
      }
      let value = event.target.textContent.trim();
      if (value == '') value = null;
      item[campo] = value;
      event.target.blur();
      this.appref.tick();
      this.globals.Api('/crm-config-edit', this.data).subscribe((data:any) => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
      });
      return false;
    }
  }
  ValFB(campo:string, event:any, item:any) {
    if (campo != 'whatsapp_cuota' && campo != 'whatsapp_pago_desde' &&
      (!event.target.textContent || event.target.textContent.trim() == '')) return;
    let value = event.target.textContent.trim();
    if (value == '') value = null;
    item[campo] = value;
    this.globals.Api('/crm-config-edit', this.data).subscribe((data:any) => {
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
    });
  }

}
