<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row-equal-cols">
                        <div class="col-lg-8 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">{{title}}</h1>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li><a [routerLink]="['/crm-clientes']">Contactos</a></li>
                                <li>{{title}}</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6 col-xs-12 col-botones text-center">
                          <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                          <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                        </div>
                    </div>
                </div>

                <div class="block full break-padding">
                    <div class="block-title">
                        <ul class="nav nav-tabs" data-toggle="tabs">
                            <li class="active"><a href="#tab-general">Datos generales</a></li>
                        </ul>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane active" id="tab-general">
                              
                            <div class="row">
                              <div class="col-lg-4 col-md-12">
                                <div *ngIf="!data.imagen || data.imagen == ''" class="cursor text-center m-t-10 no-image" (click)="SelecImagen()">
                                  <i class="icofont-camera-alt font-50"></i>
                                </div>
                                <div class="img-preview-container cursor relative" (click)="SelecImagen()">
                                  <img *ngIf="data.imagen && data.imagen != ''" [src]="data.imagen">
                                  <button *ngIf="data.imagen && data.imagen != ''" class="btn btn-danger absolute" (click)="BorrarImagen($event)" style="top:0;right:0"><i class="fa fa-trash"></i></button>
                                </div>
                              </div>
                              <div class="col-lg-8 col-md-12">
                                
                                <div class="form-group">
                                    <label>Tipo / Lista</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.tipo" maxlength="100" list="listas">
                                    <datalist id="listas">
                                      <option [value]="item.tipo" *ngFor="let item of listas"></option>
                                    </datalist>
                                </div>
                                <div class="row">
                                  <div class="col-md-6 col-xs-12">
                                    <div class="form-group">
                                        <label>Nombre</label>
                                        <input type="text" class="form-control" [(ngModel)]="data.nombre" maxlength="80">
                                    </div>
                                  </div>
                                  <div class="col-md-6 col-xs-12">
                                    <div class="form-group">
                                        <label>Apellidos</label>
                                        <input type="text" class="form-control" [(ngModel)]="data.apellidos" maxlength="80">
                                    </div>
                                  </div>
                                </div>
                                <div class="row m-t-10">
                                  <div class="col-md-8 col-xs-12">
                                    <div class="form-group">
                                        <label>Empresa</label>
                                        <input type="text" class="form-control" [(ngModel)]="data.empresa" maxlength="80">
                                    </div>
                                  </div>
                                  <div class="col-md-4 col-xs-12">
                                    <div class="form-group">
                                        <label>NIF / CIF</label>
                                        <input type="text" class="form-control" [(ngModel)]="data.nifcif" maxlength="20">
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5 col-xs-12">
                                    <div class="form-group">
                                      <label>Direcci&oacute;n</label>
                                      <input type="text" class="form-control" [(ngModel)]="data.direccion" maxlength="200">
                                    </div>
                                  </div>
                                  <div class="col-md-3 col-xs-12">
                                    <div class="form-group">
                                      <label>C&oacute;digo postal</label>
                                      <input type="text" class="form-control" [(ngModel)]="data.codpostal" maxlength="10"
                                        (change)="DatosLocalidad()">
                                    </div>
                                  </div>
                                  <div class="col-md-4 col-xs-12">
                                    <div class="form-group">
                                      <label>Localidad</label>
                                      <input type="text" class="form-control" [(ngModel)]="data.localidad" maxlength="80">
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-3 col-xs-12">
                                    <div class="form-group">
                                      <label>Provincia</label>
                                      <input type="text" class="form-control" [(ngModel)]="data.provincia" maxlength="80">
                                    </div>
                                  </div>
                                  <div class="col-md-3 col-xs-12">
                                    <div class="form-group">
                                      <label class="text-primary"><i class="fa fa-phone"></i> Tel&eacute;fono</label>
                                      <input type="tel" class="form-control" [(ngModel)]="data.telefono" maxlength="40" (keyup)="LimpiarTelefono()">
                                    </div>
                                  </div>
                                  <div class="col-md-6 col-xs-12">
                                    <div class="form-group">
                                      <label class="text-primary"><i class="fa fa-envelope"></i> Email</label>
                                      <input type="email" class="form-control" [(ngModel)]="data.email" maxlength="80">
                                    </div>
                                  </div>
                                </div>
                                
                              </div>
                            </div>

                        </div>
                    </div>
                </div>
    
                <div class="col-botones">
                  <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                  <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>