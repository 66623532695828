<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row-equal-cols">
                        <div class="col-lg-8 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">{{title}}</h1>
                                <!-- <button class="btn btn-xs btn-default middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button> -->
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li><a [routerLink]="['/crm-plantillas']">Plantillas</a></li>
                                <li>{{title}}</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6 col-xs-12 col-botones text-center">
                          <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                          <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                        </div>
                    </div>
                </div>

                <div class="block full break-padding">
                    <div class="block-title"></div>
                    
                    <div class="row">
                        <div class="col-md-6 col-xs-12">
                          <div class="form-group">
                            <label>Asunto <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" [(ngModel)]="data.asunto" maxlength="80">
                          </div>
                        </div>
                        <div class="col-md-3 col-xs-12">
                          <div class="form-group">
                            <label>Tipo <span class="text-danger">*</span></label>
                            <select class="form-control" [(ngModel)]="data.tipo">
                                <option value="email" selected>Email</option>
                                <option value="whatsapp" selected>WhatsApp</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-3 col-xs-12">
                          <div class="form-group">
                            <label>Idioma <span class="text-danger">*</span></label>
                            <select class="form-control" [(ngModel)]="data.idioma">
                                <option value="es" selected>Español</option>
                                <option value="en" selected>Inglés</option>
                                <option value="fr" selected>Francés</option>
                                <option value="pt" selected>Portugués</option>
                            </select>
                          </div>
                        </div>
                    </div>
                    
                    <div class="form-group {{data.tipo == 'email' ? null : 'hidden'}}">
                        <label>Plantilla <span class="text-danger">*</span></label>
                        <div id="plantilla"></div>
                    </div>
                    <div class="row {{data.tipo == 'whatsapp' ? null : 'hidden'}}">
                      <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                          <label>Plantilla <span class="text-danger">*</span></label>
                          <textarea class="form-control" rows="20" [(ngModel)]="data.plantilla" maxlength="1024"></textarea>
                        </div>
                      </div>
                      <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                          <label>Url Imagen</label>
                          <input type="url" class="form-control" [(ngModel)]="data.imagen" maxlength="400">
                        </div>
                        <h4>Botón enlace</h4>
                        <div class="form-group">
                          <label>Título</label>
                          <input type="text" class="form-control" [(ngModel)]="data.btn_title" maxlength="50">
                        </div>
                        <div class="form-group">
                          <label>Url</label>
                          <input type="url" class="form-control" [(ngModel)]="data.btn_url" maxlength="400">
                        </div>
                      </div>
                    </div>
                    
                </div>
    
                <div class="col-botones">
                  <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                  <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>