<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row-equal-cols">
                        <div class="col-lg-8 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">{{title}}</h1>
                                <!-- <button class="btn btn-xs btn-default middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button> -->
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li><a [routerLink]="['/usuarios']">Usuarios</a></li>
                                <li>{{title}}</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6 col-xs-12 col-botones text-center">
                          <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                          <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                        </div>
                    </div>
                </div>

                <div class="block full break-padding">
                    <div class="block-title"></div>
                    
                              
                    <div class="row">
                        <div class="col-lg-4 col-md-12">
                          <div *ngIf="!data.imagen || data.imagen == ''" class="cursor text-center m-t-10 no-image" (click)="SelecImagen()">
                            <i class="icofont-camera-alt font-50"></i>
                          </div>
                          <div class="img-preview-container cursor relative" (click)="SelecImagen()">
                            <img *ngIf="data.imagen && data.imagen != ''" [src]="data.imagen">
                            <small class="display-block">(<i class="fa fa-arrows-alt"></i> proporci&oacute;n cuadrada)</small>
                            <button *ngIf="data.imagen && data.imagen != ''" class="btn btn-danger absolute" (click)="BorrarImagen($event)" style="top:0;right:0"><i class="fa fa-trash"></i></button>
                          </div>
                        </div>
                        <div class="col-lg-8 col-md-12">
                          
                          <div class="row">
                            <div class="col-md-4 col-xs-12">
                              <div class="form-group">
                                <label class="text-primary">Nombre *</label>
                                <input type="text" class="form-control" [(ngModel)]="data.nombre" maxlength="80">
                              </div>
                            </div>
                            <div class="col-md-4 col-xs-12">
                              <div class="form-group">
                                <label class="text-primary">Apellidos *</label>
                                <input type="text" class="form-control" [(ngModel)]="data.apellidos" maxlength="80">
                              </div>
                            </div>
                            <div class="col-md-4 col-xs-12">
                              <label class="text-primary">Rol *</label>
                              <div class="form-group">
                                <select class="form-control" placeholder="Rol" [(ngModel)]="data.id_rol" *ngIf="globals.me && globals.me.id_rol == 0">
                                <option *ngFor="let item of roles" [value]="item.id">{{item.rol}}</option>
                                </select>
                                <select class="form-control" placeholder="Rol" [(ngModel)]="data.id_rol" *ngIf="globals.me && (globals.me.id_rol == 1 || globals.me.id_rol == 2)">
                                <option *ngFor="let item of roles" [value]="item.id" [disabled]="item.id == 0 || item.id == 1">{{item.rol}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-8 col-xs-12">
                              <div class="form-group">
                                <label>Empresa</label>
                                <input type="text" class="form-control" [(ngModel)]="data.empresa" maxlength="80">
                              </div>
                            </div>
                            <div class="col-md-4 col-xs-12">
                              <div class="form-group">
                                <label>NIF / CIF</label>
                                <input type="text" class="form-control" [(ngModel)]="data.nifcif" maxlength="20">
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-5 col-xs-12">
                              <div class="form-group">
                                <label>Direcci&oacute;n</label>
                                <input type="text" class="form-control" [(ngModel)]="data.direccion" maxlength="200">
                              </div>
                            </div>
                            <div class="col-md-3 col-xs-12">
                              <div class="form-group">
                                <label>C&oacute;digo postal</label>
                                <input type="text" class="form-control" [(ngModel)]="data.codpostal" maxlength="10"
                                  (change)="DatosLocalidad()">
                              </div>
                            </div>
                            <div class="col-md-4 col-xs-12">
                              <div class="form-group">
                                <label>Localidad</label>
                                <input type="text" class="form-control" [(ngModel)]="data.localidad" maxlength="80">
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-3 col-xs-12">
                              <div class="form-group">
                                <label>Provincia</label>
                                <input type="text" class="form-control" [(ngModel)]="data.provincia" maxlength="80">
                              </div>
                            </div>
                            <div class="col-md-3 col-xs-12">
                              <div class="form-group">
                                <label>Tel&eacute;fono</label>
                                <input type="tel" class="form-control" [(ngModel)]="data.telefono" maxlength="20">
                              </div>
                            </div>
                          </div>
                          
                          <div class="block">
                            <div class="block-title"><h2><i class="fa fa-sign-in"></i> Acceso al sistema</h2></div>
                            <div class="block-content">
                              <div class="row">
                                <div class="col-md-5 col-xs-12">
                                  <div class="form-group">
                                    <label class="text-primary"><i class="fa fa-envelope"></i> Email *</label>
                                    <input type="email" class="form-control"[(ngModel)]="data.email" maxlength="80">
                                  </div>
                                </div>
                                <div class="col-md-4 col-xs-12">
                                  <div class="form-group" *ngIf="!data.id || data.id == ''">
                                    <label class="text-primary"><i class="fa fa-lock"></i> Contraseña *</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.clave" maxlength="50">
                                    <a class="btn btn-block btn-default m-t-10" (click)="GenerarClave()"><i class="fa fa-lock"></i> Generar</a>
                                  </div>
                                  <div class="form-group" *ngIf="data.id && data.id != ''">
                                    <a class="btn btn-sm btn-warning text-white m-t-30" (click)="EditarClave()"><i class="fa fa-lock"></i> Cambiar contraseña</a>
                                  </div>
                                </div>
                                <div class="col-md-3 col-xs-12">
                                  <div class="form-group">
                                    <label class="text-primary">Acceso</label>
                                    <select class="form-control" [(ngModel)]="data.acceso">
                                      <option value="1">Sí</option>
                                      <option value="0">No</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    
                </div>
    
                <div class="col-botones">
                  <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                  <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>
<div class="modal inmodal fade" id="modal-editar-clave-usuario" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
            <h4 class="modal-title">{{data.title}}</h4>
        </div>
        <div class="modal-body">
            <p>Nombre: <strong>{{data.nombre}}</strong></p>
            <p>Email: <strong>{{data.email}}</strong></p>
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Nueva contrase&ntilde;a <span class="text-danger">*</span></label>
                <div class="col-lg-8">
                    <input type="text" class="form-control" [(ngModel)]="data.clave" maxlength="50">
                    <a class="btn btn-block btn-default m-t-10" (click)="GenerarClave()"><i class="fa fa-lock"></i> Generar</a>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button id="btnGuardarClaveUsuarioModal" type="button" class="btn btn-primary" (click)="GuardarClaveUsuario()">Guardar</button>
        </div>
        </div>
    </div>
</div>