<div id="sidebar">
    <!-- Sidebar Brand -->
    <div id="sidebar-brand" class="themed-background">
        <a [routerLink]="['/dashboard']" class="sidebar-title">
            <img src="assets/logo-isotipo.png" alt=""> <span class="sidebar-nav-mini-hide">SPG<strong>Manager</strong></span>
        </a>
    </div>
    <!-- END Sidebar Brand -->

    <!-- Wrapper for scrolling functionality -->
    <div id="sidebar-scroll">
        <!-- Sidebar Content -->
        <div class="sidebar-content">
            <!-- Sidebar Navigation -->
            <ul class="sidebar-nav" *ngIf="globals.me && (globals.me.id_rol == 0 || globals.me.id_rol == 1 || globals.me.id_rol == 2)">
                <li>
                    <a [routerLink]="['/dashboard']" routerLinkActive="active"><i class="gi gi-compass sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Inicio</span></a>
                </li>
                <li>
                    <a [routerLink]="['/config-app']" routerLinkActive="active"><i class="icofont-settings sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Configuración</span></a>
                </li>
                <li>
                    <a [routerLink]="['/email-builder']" routerLinkActive="active"><i class="gi gi-envelope sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Generador Emails</span></a>
                </li>
                <li>
                    <a [routerLink]="['/seguimiento-servidores']" routerLinkActive="active"><i class="fa fa-server sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Seguimiento servidores</span></a>
                </li>
                <li>
                    <a [routerLink]="['/generador-logs-acceso']" routerLinkActive="active"><i class="fa fa-unlock-alt sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Generador Logs Acceso</span></a>
                </li>
                <li>
                    <a [routerLink]="['/generador-dns']" routerLinkActive="active"><i class="fa fa-shield sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Generador DNS</span></a>
                </li>
                <li class="sidebar-separator">
                    <i class="fa fa-ellipsis-h"></i>
                </li>
                <li>
                    <a [routerLink]="['/licencias']" routerLinkActive="active"><i class="fa fa-key sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Licencias</span></a>
                </li>
                <li>
                    <a [routerLink]="['/usuarios']" routerLinkActive="active"><i class="fa fa-users sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Usuarios</span></a>
                </li>
                <li class="sidebar-separator">
                    <i class="fa fa-ellipsis-h"></i>
                </li>
                <li>
                    <a>CRM</a>
                </li>
                <li>
                    <a [routerLink]="['/crm-clientes']" routerLinkActive="active"><i class="fa fa-users sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Contactos</span></a>
                </li>
                <li>
                    <a [routerLink]="['/crm-campanas']" routerLinkActive="active"><i class="fa fa-send sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Campañas</span></a>
                </li>
                <li>
                    <a [routerLink]="['/crm-plantillas']" routerLinkActive="active"><i class="fa fa-envelope sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Plantillas</span></a>
                </li>
                <li>
                    <a [routerLink]="['/crm-mensajes']" routerLinkActive="active"><i class="fa fa-whatsapp sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Mensajes</span></a>
                </li>
                <li>
                    <a [routerLink]="['/crm-config']" routerLinkActive="active"><i class="fa fa-cog sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Configuración</span></a>
                </li>
            </ul>
            <ul class="sidebar-nav" *ngIf="globals.me && (globals.me.id_rol == 3)">
                <li>
                    <a [routerLink]="['/dashboard']" routerLinkActive="active"><i class="gi gi-compass sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Inicio</span></a>
                </li>
                <li class="sidebar-separator">
                    <i class="fa fa-ellipsis-h"></i>
                </li>
                <li>
                    <a [routerLink]="['/crm-mensajes']" routerLinkActive="active"><i class="fa fa-whatsapp sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Mensajes</span></a>
                </li>
            </ul>
            <!-- END Sidebar Navigation -->
        </div>
        <!-- END Sidebar Content -->
    </div>
    <!-- END Wrapper for scrolling functionality -->

    <!-- Sidebar Extra Info -->
    <div id="sidebar-extra-info" class="sidebar-content sidebar-nav-mini-hide">
        <!--
        <div class="push-bit">
            <span class="pull-right">
                <a href="javascript:void(0)" class="text-muted"><i class="fa fa-plus"></i></a>
            </span>
            <small><strong>78 GB</strong> / 100 GB</small>
        </div>
        <div class="progress progress-mini push-bit">
            <div class="progress-bar progress-bar-primary" role="progressbar" aria-valuenow="78" aria-valuemin="0" aria-valuemax="100" style="width: 78%"></div>
        </div>
        -->
        <div class="text-center">
            <small>Creado por <a href="https://pecesgordos.es/" target="_blank">Peces Gordos Estudio</a></small><br>
        </div>
    </div>
    <!-- END Sidebar Extra Info -->
</div>