import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;

@Component({
  selector: 'app-generador-dns',
  templateUrl: './generador-dns.component.html'
})
export class GeneradorDNSComponent implements OnInit {
  public data:any = {servidor: 'Thor'};
  public servidores:any = [
    {nombre: 'Zeus', dominio: 'zeuscloud.pecesgordosestudio.es', ip: '46.183.116.30'},
    {nombre: 'Thor', dominio: 'thorcloud.pecesgordosestudio.es', ip: '185.166.213.21'},
    {nombre: 'Afrodita', dominio: 'afroditacloud.pecesgordosestudio.es', ip: '185.253.153.174'},
    {nombre: 'Hera', dominio: 'heracloud.pecesgordosestudio.es', ip: '185.254.206.204'},
  ];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
  }

  Generar() {
    if (!this.data.dominio || this.data.dominio == '') return;
    let servidor:any = this.servidores.find((el:any) => el.nombre == this.data.servidor);
    this.data.dns = [];
    this.data.dns.push({
      tipo: 'A',
      campo: `${this.data.dominio}.`,
      valor: servidor.ip
    });
    this.data.dns.push({
      tipo: 'A',
      campo: `*.${this.data.dominio}.`,
      valor: servidor.ip
    });
    this.data.dns.push({
      tipo: 'CNAME',
      campo: `wwww.${this.data.dominio}.`,
      valor: `${this.data.dominio}.`,
    });
    this.data.dns.push({
      tipo: 'MX',
      campo: `(Prioridad 10)`,
      valor: `${servidor.dominio}`,
    });
    this.data.dns.push({
      tipo: 'TXT',
      campo: ``,
      valor: `v=spf1 mx a:${this.data.dominio} ip4:${servidor.ip} -all`,
    });
    this.data.dns.push({
      tipo: 'TXT',
      campo: `_dmarc`,
      valor: `v=DMARC1; p=reject; rua=mailto:abuse@${this.data.dominio}; ruf=mailto:abuse@${this.data.dominio}`,
    });
    this.data.dns.push({
      tipo: 'TXT',
      campo: `default._domainkey`,
      valor: `v=DKIM1; p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC1DtqOEHuZWVVLhuWbasdh4qz+esBB4XSVceGze5Qdg77x/9EC5/N92jJr+aA20JlS4C3iF5NidiFGYu6OfEMTF/X4dXRb51OEnnk4pG8s9ywIfWl5VvAwS1napAe1j+D8cRo6a2ZQPPquyLeQSTLDlwfa69MDBxgV6OKH+OhB6QIDAQAB;`,
    });
    this.data.dns.push({
      tipo: 'TXT',
      campo: `_domainkey`,
      valor: `o=-`,
    });
  }

}
