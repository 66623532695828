<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content" style="padding-bottom: 50px;">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-8 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">Mensajes</h1>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>Mensajes</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6 col-xs-12 col-botones">
                            <!-- <select class="form-control m-t-30" style="width: auto;"
                                (change)="ChangeCuenta($event)"
                                *ngIf="config.cuentas_whatsapp && config.cuentas_whatsapp.length">
                                <option [value]="item.id" *ngFor="let item of this.config.cuentas_whatsapp">{{item.nombre}} - <span>{{item.whatsapp_fbid}}</span></option>
                            </select> -->
                        </div>
                    </div>
                </div>
                
                <iframe [src]="url_iframe" frameborder="0" *ngIf="url_iframe && url_iframe != ''" style="width:100%;height:78vh"></iframe>

                <!--
                <div class="row">
                    <div class="col-md-4 col-sm-12">
                        <ul class="list-group whatsapp-contactos">
                            <li class="list-group-item active">
                                <i class="fa fa-whatsapp"></i> Contactos
                            </li>
                            <div class="whatsapp-contactos-container">
                                <li class="list-group-item {{item.active ? 'active-contacto' : null}} cursor" *ngFor="let item of contactos" (click)="SeleccionarContacto(item)">
                                    <a class="font-bold">
                                        <span *ngIf="item.nombre" style="color: #545454;">{{item.nombre}}</span>
                                        <span *ngIf="item.numero">{{item.numero}}</span>
                                        <span *ngIf="item.cuenta" class="font-italic font-12 text-muted">{{item.cuenta}}</span>
                                    </a>
                                    <span class="badge" *ngIf="item.total_noleidos">{{item.total_noleidos}}</span>
                                    <span class="flecha" *ngIf="item.active"><i class="fa fa-chevron-right"></i></span>
                                </li>
                            </div>
                        </ul>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <ul class="list-group whatsapp-chat">
                            <li class="list-group-item active">
                                <span *ngIf="!contacto">Seleccione un contacto</span>
                                <span *ngIf="contacto && contacto.nombre">{{contacto.nombre}}</span>&nbsp;
                                <span *ngIf="contacto && contacto.numero">{{contacto.numero}}</span>
                                <a class="btn btn-danger btn-xs absolute" style="right:10px" (click)="DelConversacion()"><i class="fa fa-trash"></i></a>
                            </li>
                            <li class="list-group-item whatsapp-chat-container">
                                
                                <div class="message {{item.origen ? 'received' : null}}" *ngFor="let item of mensajes">
                                    <div class="message-inner">
                                        <p [innerHTML]="item.mensaje" *ngIf="item.mensaje"></p>
                                        <a [href]="item.imagen" *ngIf="item.imagen" class="link-img-principal">
                                            <img [src]="item.imagen" alt="" class="img-principal">
                                        </a>
                                        <span class="date">{{item.alta_format}} {{item.hora}}</span>
                                    </div>
                                </div>

                            </li>
                            <li class="list-group-item whatsapp-write-container {{mensajes && mensajes.length ? null : 'hidden'}}">
                                <div class="lead emoji-picker-container">
                                    <textarea class="form-control" id="txtmensaje" (keyup.enter)="Enviar()" data-emojiable="true"></textarea>
                                </div>
                                <a id="btnenviar" class="btn btn-circle btn-primary" (click)="Enviar()"><i class="fa fa-send"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
                -->

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>