import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let grapesjs: any;

@Component({
  selector: 'app-crm-edit-plantilla',
  templateUrl: './edit-plantilla.component.html'
})
export class CRMEditPlantillaComponent implements OnInit {
  public title:string = 'Nueva plantilla';
  public data:any = {tipo: 'email', idioma: 'es'};
  public imgsubir:string = '';

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(1);
    $.checkrolredirect(2);
    $.checkrolredirect(3);
    $.checkrolredirect(4);
    if (window.location.pathname.indexOf('add') != -1) this.globals.passData = null;
    if (this.globals.passData && this.globals.passData != '' && Object.keys(this.globals.passData).length > 0) this.data = this.globals.passData;
    if (!this.data.id || this.data.id == '') {
      this.title = 'Nueva plantilla';
    } else {
      this.title = this.data.asunto;
    }
    $.editor = grapesjs.init({
      container: '#plantilla',
      components: (this.data.plantilla ? this.data.plantilla : null),
      style: '',
      storageManager: false,
      plugins: ['gjs-preset-newsletter'],
    });
    const prop = $.editor.StyleManager.getProperty('typography', 'font-family');
    // prop.set('options', [
    //     {value: "'Poppins', sans-serif", name: 'Poppins'},
    //     {value: "'Raleway', sans-serif", name: 'Raleway'},
    //     {value: "'Geomanist', sans-serif", name: 'Geomanist'}
    // ]);
  }

  Cancelar() {
    this.ngZone.run(() => this.router.navigateByUrl('/crm-plantillas')).then();
  }
  Guardar() {
    if (this.data.tipo == 'email') this.data.plantilla = $.editor.runCommand('gjs-get-inlined-html');
    if (
      !this.data.asunto
      || !this.data.tipo
      || !this.data.idioma
      || !this.data.plantilla
      || this.data.asunto == ''
      || this.data.tipo == ''
      || this.data.idioma == ''
      || this.data.plantilla == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    if (!this.data.id || this.data.id == '') {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/crm-plantillas-add', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.Cancelar();
      }, error => {
        $.buttoncancelloading('.btn-guardar');
      });
    } else {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/crm-plantillas-edit', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        swal('Guardado', 'Datos guardados correctamente', 'success');
      }, error => {
        $.buttoncancelloading('.btn-guardar');
      });
    }
  }

}
