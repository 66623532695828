import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;

@Component({
  selector: 'app-generador-logs-acceso',
  templateUrl: './generador-logs-acceso.component.html'
})
export class GeneradorLogsAccesoComponent implements OnInit {
  public data:any = {};

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    this.data.tiempo = '3m';
    this.data.ips = [
      '26.213.145.143',
      '145.104.128.133',
      '208.11.164.151',
      '147.59.57.45',
      '161.69.173.195',
      '33.51.158.7',
      '158.175.59.172',
      '147.238.193.187',
      '229.169.181.192',
      '229.0.102.194'
    ];
    this.data.devices = [
      'Windows 10',
      'Windows 10',
      'Android',
      'Android',
      'Windows 10',
      'Android',
      'Android',
      'Android',
      'Windows 10',
      'Android'
    ];
    this.data.browsers = [
      'Chrome',
      'Chrome',
      'Chrome',
      'Chrome',
      'Chrome',
      'Chrome',
      'Chrome',
      'Chrome',
      'Chrome',
      'Chrome'
    ];
  }

  CargarEmails() {
    if (!this.data.dominio || this.data.dominio == '') {
      this.data.email1 = '';
      this.data.email2 = '';
      this.data.email3 = '';
      this.data.email4 = '';
      this.data.email5 = '';
      this.data.email6 = '';
      this.data.email7 = '';
      this.data.email8 = '';
      this.data.email9 = '';
      this.data.email10 = '';
      return;
    }

    this.data.dominio = this.data.dominio.replace('https:', '').replace('http:', '').replace('www.', '');
    this.data.dominio = this.data.dominio.replace(/\//g, '');
    this.data.dominio = this.data.dominio.trim();

    let nombre = this.data.dominio.replace('.es', '').replace('.com', '').replace('.net', '');
    this.data.email1 = 'info@'+this.data.dominio;
    this.data.email2 = 'comunicacion@'+this.data.dominio;
    this.data.email3 = 'info.'+nombre+'@gmail';
    this.data.email4 = 'gestion@'+this.data.dominio;
    this.data.email5 = 'noelia.'+nombre+'@gmail.com';
    this.data.email6 = 'alvaro.'+nombre+'@gmail.com';
    this.data.email7 = 'pedro.'+nombre+'@gmail.com';
    this.data.email8 = 'fcastilloper80@gmail.com';
    this.data.email9 = 'alopezd22@outlook.com';
    this.data.email10 = 'maria.'+nombre+'@gmail.com';
  }

  Generar() {
    if (!this.data.dominio || this.data.dominio == '') return;
    let endDate = moment();
    let startDate = null;
    if (this.data.tiempo == '6m') startDate = moment(endDate).subtract(6, 'month').startOf('month');
    if (this.data.tiempo == '3m') startDate = moment(endDate).subtract(3, 'month').startOf('month');
    if (this.data.tiempo == '1m') startDate = moment(endDate).subtract(1, 'month').startOf('month');

    function random(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    }

    let logs = '';
    while (startDate.diff(endDate) < 0) {
      let horas = [];
      for (let i = 0; i < random(4, 6); i++) {
        let hora = random(8, 20);
        let minutos = random(0, 59);
        let segundos = random(0, 59);

        if (startDate.diff(endDate, 'day') == 0) {
          hora = random(8, endDate.hour());
          minutos = random(0, endDate.minute());
          segundos = random(0, endDate.second());
        }

        horas.push(hora.toString().padStart(2, '0') + ':' + minutos.toString().padStart(2, '0') + ':' + segundos.toString().padStart(2, '0'));
      }
      horas.sort();

      let emails = [this.data.email1, this.data.email2, this.data.email3, this.data.email4, this.data.email5,
        this.data.email6, this.data.email7, this.data.email8, this.data.email9, this.data.email10];
      emails = emails.filter((email:any) => {return (email && email != '');});

      for (let hora of horas) {
        let email = emails[Math.floor(Math.random()*emails.length)];
        let ip = this.data.ips[Math.floor(Math.random()*this.data.ips.length)];
        let device = this.data.devices[Math.floor(Math.random()*this.data.devices.length)];
        let browser = this.data.browsers[Math.floor(Math.random()*this.data.browsers.length)];

        logs += startDate.format('MMMM D YYYY')+" "+hora+" ";
        logs += "method[POST] ";
        logs += "IP: "+ip+"\n";
        logs += startDate.format('MMMM D YYYY')+" "+hora+" ";
        logs += "method[POST]: ";
        logs += "new session opened by user "+email+"\n";
        logs += startDate.format('MMMM D YYYY')+" "+hora+" ";
        logs += email+": ";
        logs += "operation system["+device+"], browser["+browser+"] \n";
      }

      startDate.add(1, 'days');
    }
    this.data.logs = logs;
    this.data.logs_html = logs.replace(/(?:\r\n|\r|\n)/g, '<br>');
    
  }

  Imprimir() {
    if (!this.data.dominio || this.data.dominio == '') return;
    if (!this.data.logs_html || this.data.logs_html == '') return;
    let win:any = window.open('', '_blank');
    win.document.write(this.data.logs_html);
    win.document.close();
    win.focus();
    win.moveTo(50, 50);
    setTimeout(() => {
      win.print();
    }, 500);
  }
}
