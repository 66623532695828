import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let grapesjs: any;

@Component({
  selector: 'app-edit-usuario',
  templateUrl: './edit-usuario.component.html'
})
export class EditUsuarioComponent implements OnInit {
  public title:string = 'Nuevo usuario';
  public data:any = {id_rol: 1, acceso: 1};
  public imgsubir:string = '';
  public roles = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    if (window.location.pathname.indexOf('add') != -1) this.globals.passData = null;
    if (this.globals.passData && this.globals.passData != '' && Object.keys(this.globals.passData).length > 0) this.data = this.globals.passData;
    if (!this.data.id || this.data.id == '') {
      this.title = 'Nuevo usuario';
    } else {
      this.title = this.data.nombre + (this.data.apellidos ? ' ' + this.data.apellidos : '');
    }
    this.globals.Api('/roles').subscribe(data => {
      if (!data || data.length == 0) return;
      this.roles = data;
    });
  }
  
  SelecImagen() {
    let input = this.globals.FileInput('image/*', event => {
      this.globals.Base64Image(input, base64 => {
        this.data.imagen = base64;
        this.imgsubir = base64;
        this.appref.tick();
      });
    }, 800);
    input.click();
  }
  BorrarImagen(event:any) {
    event.stopPropagation();
    swal({
      title: "¿Borrar imagen del usuario?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      if (!this.data.id || this.data.id == '') {
        this.imgsubir = '';
        this.data.imagen = '';
        return;
      }
      this.globals.Api('/borrar-imagen-usuario', {id: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.imgsubir = '';
        this.data.imagen = '';
        this.appref.tick();
      });
    });
  }
  
  DatosLocalidad() {
    this.globals.Api('/localidad', {codpostal: this.data.codpostal}).subscribe(data => {
      if (!data) {
        this.data.localidad = '';
        this.data.provincia = '';
        this.appref.tick();
        return;
      }
      this.data.localidad = data.localidad
      this.data.provincia = data.provincia;
      this.appref.tick();
    });
  }

  EditarClave() {
    this.data.title = 'Editar contraseña usuario';
    this.data.clave = '';
    $('#modal-editar-clave-usuario').modal('show');
  }
  GenerarClave() {
    this.data.clave = this.globals.GeneratePassword();
    this.appref.tick();
  }
  GuardarClaveUsuario() {
    if (
      this.data.clave == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    $.buttonloading('btnGuardarClaveUsuarioModal', 'Enviando');
    this.globals.Api('/editar-clave-usuario', this.data).subscribe(data => {
      $.buttoncancelloading('btnGuardarClaveUsuarioModal');
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      $('#modal-editar-clave-usuario').modal('hide');
      swal('Guardado', 'Datos guardados correctamente', 'success');
    }, error => {
      $.buttoncancelloading('btnGuardarClaveUsuarioModal');
    });
  }

  Cancelar() {
    this.ngZone.run(() => this.router.navigateByUrl('/usuarios')).then();
  }
  Guardar() {
    if (
      !this.data.nombre
      || !this.data.apellidos
      || !this.data.email
      || this.data.nombre == ''
      || this.data.apellidos == ''
      || this.data.email == ''
      || ((!this.data.clave || this.data.clave == '') && (!this.data.id || this.data.id == ''))
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (!this.globals.ValidateEmail(this.data.email)) {
      swal('Email no válido', 'Por favor, introduzca un email válido.', 'error');return;
    }

    this.data.img = null;
    if (this.imgsubir != '') this.data.img = this.imgsubir;

    if (!this.data.id || this.data.id == '') {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/usuarios-add', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          if (data.value == 'Email repetido') {
            swal('Email existente', 'Existe un usuario con este email', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.Cancelar();
      }, error => {
        $.buttoncancelloading('.btn-guardar');
      });
    } else {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/usuarios-edit', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          if (data.value == 'Email repetido') {
            swal('Email existente', 'Existe un usuario con este email', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        swal('Guardado', 'Datos guardados correctamente', 'success');
      }, error => {
        $.buttoncancelloading('.btn-guardar');
      });
    }
  }

}
