import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let XLSX: any;

@Component({
  selector: 'app-crm-clientes',
  templateUrl: './clientes.component.html'
})
export class CRMClientesComponent implements OnInit {
  public data:any = [];
  public params:any = {
    page_index: 1,
    page_size: 50,
    tipo: ''
  };
  public listas:any = [];

  public step:number = 1;
  public dataimportar:any = {};
  public filas:any = [];
  public columnas:any = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(1);
    $.checkrolredirect(2);
    $.checkrolredirect(3);
    $.checkrolredirect(4);
    this.CargarGrid();
    this.globals.GridOrderJS((order, dir) => {
      this.params.order = order;
      this.params.dir = dir;
      this.params.page_index = 1;
      this.CargarGrid();
      this.appref.tick();
    });
    this.CargarListas();
  }

  CargarListas() {
    this.globals.Api('/crm-listas').subscribe((data:any) => {
      if (!data || !data.length) return;
      this.listas = data;
      if (!this.data.tipo && this.listas.length) this.data.tipo = this.listas[0].tipo;
    });
  }
  CargarGrid(paginationload:boolean = true) {
    if (this.globals.GetData()) this.data = this.globals.GetData();
    this.globals.Api('/crm-clientes', this.params).subscribe((data:any) => {
      if (!data || !data.length) {
        this.data = [];
        this.globals.SaveData([]);
        return;
      }
      this.globals.SaveData(data);
      this.data = data;
      this.appref.tick();
      if (!paginationload) return;
      this.globals.Api('/crm-clientes/total', this.params).subscribe((data:any) => {
        if (!data || !data.length) return;
        this.params.total = data[0].total;
        this.params.pages = Math.ceil(this.params.total / this.params.page_size);
      });
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') this.params.page_index -= 1;
    if (dir == 'next') this.params.page_index += 1;
    this.CargarGrid(false);
  }
  Buscar() {
    this.params.page_index = 1;
    this.CargarGrid();
  }
  Nuevo() {
    this.globals.passData = '';
    this.ngZone.run(() => this.router.navigateByUrl('/crm-clientes/add')).then();
  }
  Editar(item:any) {
    this.globals.passData = JSON.parse(JSON.stringify(item));
    this.ngZone.run(() => this.router.navigateByUrl('/crm-clientes/edit')).then();
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar contacto?",
      text: '¡No se podrán deshacer los cambios!',
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/crm-clientes-del', {id: item.id}).subscribe((data:any) => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.splice(this.data.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  Importar() {
    Object.keys(this.dataimportar).forEach(item => {this.dataimportar[item] = ''});
    this.dataimportar.tipo = 'Lista general';
    this.dataimportar.min_fila = 1;
    this.filas = [];
    for (let i = 1;i <= 500;i++) this.filas.push(i);
    this.globals.Api('/crm-importador-columnas', {tabla: 'crm_clientes'}).subscribe((data:any) => {
      if (!data || !data.length) return;
      this.columnas = data;
    });
    this.dataimportar.asignacion = {};
    if (this.globals.GetData('importador-ultima-asignacion')) this.dataimportar.asignacion = this.globals.GetData('importador-ultima-asignacion');
    if (!this.dataimportar.asignacion || this.dataimportar.asignacion == '') this.dataimportar.asignacion = {};
    this.step = 1;
    $('#modal-importar-clientes').modal('show');
  }
  Anterior() {
    if (this.step > 1) this.step -= 1;
    switch(this.step) {
      case 2: {
        setTimeout(() => {
          if (this.dataimportar.asignacion && Object.keys(this.dataimportar.asignacion).length) {
            for (let key of Object.keys(this.dataimportar.asignacion)) {
              $(`select[data-asignacion="${key}"]`).val(this.dataimportar.asignacion[key]);
            }
          }
        }, 200);
      } break;
    }
  }
  Siguiente() {
    let that = this;
    switch(this.step) {
      case 1: {
        if (
             !this.dataimportar.documento
          || this.dataimportar.documento == ''
          ) {
          swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
        }
      } break;
      case 2: {
        if (
             !this.dataimportar.asignacion
          || !Object.keys(this.dataimportar.asignacion).length
          ) {
          swal('Asignación de campos', 'Por favor, asigne al menos 1 campo para continuar', 'error');return;
        }
        let count = 0;
        for (let key of Object.keys(this.dataimportar.asignacion)) {
          if (key == 'email' || key == 'telefono') count += 1;
        }
        if (!count) {
          swal('Campos obligatorios', 'Por favor, asigne email o teléfono al menos para continuar', 'error');return;
        }
      } break;
    }
    this.step += 1;
    switch(this.step) {
      case 2: {
        var reader = new FileReader();
        var data = this.dataimportar.documento;
        var _comma = data.indexOf(","), _b64 = data.substr(0, _comma).indexOf("base64") > -1;
        var workbook = XLSX.read(data.substr(_comma + 1), {type: _b64 ? 'base64' : 'binary'});
        workbook.SheetNames.forEach(function(sheetName) {
          var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
          var json_object = JSON.stringify(XL_row_object);
          that.dataimportar.contactos = XL_row_object;
          if (that.dataimportar.contactos && that.dataimportar.contactos.length) {
            that.dataimportar.primera_fila = that.dataimportar.contactos[that.dataimportar.min_fila-1];
            // that.dataimportar.primera_fila_keys = Object.keys(that.dataimportar.primera_fila);
            that.dataimportar.primera_fila_keys = [];
            for (let item of that.dataimportar.contactos) {
              let keys = Object.keys(item);
              for (let key of keys) {
                if (that.dataimportar.primera_fila_keys.indexOf(key) == -1) that.dataimportar.primera_fila_keys.push(key);
              }
            }
            let arr:any = [];
            for (let key of that.dataimportar.primera_fila_keys) arr.push(that.dataimportar.primera_fila[key]);
            that.dataimportar.primera_fila_array = arr;
          }
          that.appref.tick();
          setTimeout(() => {
            if (that.dataimportar.asignacion && Object.keys(that.dataimportar.asignacion).length) {
              for (let key of Object.keys(that.dataimportar.asignacion)) {
                if (key.toString().toLocaleLowerCase().trim() == 'id') continue;
                $(`select[data-asignacion="${that.dataimportar.asignacion[key]}"]`).val(key);
              }
            } else {
              for (let key of that.dataimportar.primera_fila_keys) {
                let keybuscar = key.toString().toLocaleLowerCase().trim();
                if (keybuscar == 'id') continue;
                keybuscar = that.globals.CleanedString(keybuscar);
                if (keybuscar == 'codigo_postal') keybuscar = 'codpostal';
                if (keybuscar == 'cod_postal') keybuscar = 'codpostal';
                if (keybuscar == 'cod__postal') keybuscar = 'codpostal';
                if (keybuscar == 'poblacion') keybuscar = 'localidad';
                if (keybuscar == 'etiquetas') keybuscar = 'tags';
                if (keybuscar == 'creado') keybuscar = 'alta';
                if (keybuscar == 'creada') keybuscar = 'alta';
                if (keybuscar == '' || key == '') continue;
                if ($(`select[data-asignacion="${key}"] option[value="${keybuscar}"]`).length) {
                  $(`select[data-asignacion="${key}"]`).val(keybuscar);
                  that.dataimportar.asignacion[keybuscar] = key;
                }
              }
              that.globals.SaveData(that.dataimportar.asignacion, 'importador-ultima-asignacion');
            }
          }, 200);
        });
      } break;
      case 3: {
        for (let i = 0; i < this.dataimportar.contactos.length; i++) {
          for (let key of Object.keys(this.dataimportar.asignacion)) {
            this.dataimportar.contactos[i][key] = this.dataimportar.contactos[i][this.dataimportar.asignacion[key]];
          }
        }
        this.globals.SaveData(this.dataimportar.asignacion, 'importador-ultima-asignacion');
      } break;
    };
  }
  SelExcel() {
    let accept = '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
    let input = this.globals.FileInput(accept, (event:any) => {
      let extension = '.'+input.value.split('.')[input.value.split('.').length-1].toLocaleLowerCase();
      this.globals.Base64(input, (base64:any) => {
        let params = {
          id: this.dataimportar.id,
          nombre: input.value.replace(/.*[\/\\]/, '').replace(extension, ''),
          extension: extension,
          documento: base64
        };
        if (!this.dataimportar.titulo || this.dataimportar.titulo == '') this.dataimportar.titulo = params.nombre;
        this.dataimportar.docname = params.nombre;
        this.dataimportar.documento = base64;
        // this.dataimportar.extension = extension;
      });
    });
    input.click();
  }
  AsignarCampo(item:any, event:any) {
    if (event.target.value && event.target.value != '') {
      this.dataimportar.asignacion[event.target.value] = item;
    } else {
      delete this.dataimportar.asignacion[event.target.value];
    }
  }
  ImportarContactos() {
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.2)',
      imageResizeFactor: 0.5,
      text: 'Enviando datos ...'
    });
    $.buttonloading('btn-importar', 'Enviando');
    this.dataimportar.documento = null;
    this.globals.Api('/crm-importar-clientes', this.dataimportar).subscribe(data => {
      $.LoadingOverlay("hide", true);
      $.buttoncancelloading('btn-importar');
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      swal('¡Importados!', 'Contactos importados correctamente', 'success');
      $('#modal-importar-clientes').modal('hide');
      this.Buscar();
      this.CargarListas();
    }, error => {
      $.LoadingOverlay("hide", true);
      $.buttoncancelloading('btn-importar');
    });
  }
  DelLista(lista:any) {
    swal({
      title: "¿Borrar contactos de esta lista?",
      text: '¡No se podrán deshacer los cambios!',
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/crm-clientes-del-lista', {lista: lista}).subscribe((data:any) => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.params.tipo = '';
        this.Buscar();
        this.CargarListas();
      });
    });
  }
}
