import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let toastr: any;
declare let EmojiPicker: any;

@Component({
  selector: 'app-crm-mensajes',
  templateUrl: './mensajes.component.html'
})
export class CRMMensajesComponent implements OnInit {
  public url_iframe:any = '';

  constructor(
    public sanitize: DomSanitizer,
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    this.globals.Api('/crm-config').subscribe((data:any) => {
      if (!data) return;
      if (!data.cuentas_whatsapp || !data.cuentas_whatsapp.length) return;
      this.url_iframe = this.sanitize.bypassSecurityTrustResourceUrl(
        '/whatsapp-web?token_admin=9TGNHOP6Z2A7GWZXK7KV&token=DX9FO2BV46QYVD53JQ02'
      );
      // window.addEventListener('message', function(event) {
      //     if (!event.data || !event.data.height) return;
      //     $('iframe').height((event.data.height + 100) + 'px');
      // });
    });
  }

}
