<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">Generador DNS</h1>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>Generador DNS</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="block">
                    <div class="block-title"><h2>Parámetros</h2></div>
                    <div class="row">
                        <div class="col-lg-3 col-md-4 col-sm-12">
                            <div class="form-group">
                              <label>Dominio</label>
                              <input type="url" class="form-control" [(ngModel)]="data.dominio" maxlength="80" placeholder="ejemplo.com">
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-12">
                            <div class="form-group">
                              <label>Servidor</label>
                              <select class="form-control" [(ngModel)]="data.servidor">
                                <option [value]="item.nombre" *ngFor="let item of servidores">{{item.nombre}}</option>
                              </select>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-4 col-sm-12">
                          <a class="btn btn-primary btn-block m-t-25" (click)="Generar()">Generar <i class="fa fa-download"></i></a>
                        </div>
                    </div>
                </div>

                <div class="block">
                  <div class="block-title"><h2>DNS</h2></div>
                  <div class="table-responsive table-grid">
                    <table class="table table-bordered table-vcenter table-hover" *ngIf="data.dns">
                      <thead>
                        <tr>
                          <th>Tipo</th>
                          <th>Campo</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of data.dns">
                          <td>{{item.tipo}}</td>
                          <td><input type="text" class="form-control cursor" [(ngModel)]="item.campo" readonly (click)="globals.Clipboard($event, item.campo)"></td>
                          <td><input type="text" class="form-control cursor" [(ngModel)]="item.valor" readonly (click)="globals.Clipboard($event, item.valor)"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>