import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let grapesjs: any;

@Component({
  selector: 'app-email-builder',
  templateUrl: './email-builder.component.html'
})
export class EmailBuilderComponent implements OnInit {

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.ajax({ url: '/assets/email-templates/template1.html', success: function(data) { 
      var editor = grapesjs.init({
        container : '#gjs',
        components: data,
        style: '',
        storageManager: false,
        plugins: ['gjs-preset-newsletter'],
      });
      const prop = editor.StyleManager.getProperty('typography', 'font-family');
      prop.set('options', [
          {value: "'Poppins', sans-serif", name: 'Poppins'},
          {value: "'Raleway', sans-serif", name: 'Raleway'},
          {value: "'Geomanist', sans-serif", name: 'Geomanist'}
      ]);
    }});
  }

}
