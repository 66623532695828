<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-xs-12 my-auto">
                            <div class="header-section">
                              <button class="btn btn-xs btn-secondary pull-right m-t-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button>
                                <div class="dropdown">
                                  <h1 class="middle cursor" data-toggle="dropdown" id="dropdownMenu1" >Licencias
                                    <span class="caret"></span>
                                  </h1>
                                  <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                                    <li><a class="cursor" (click)="Filtrar('todo')">Todas</a></li>
                                    <li><a class="cursor" (click)="Filtrar('expiradas')">Expiradas</a></li>
                                    <li><a class="cursor" (click)="Filtrar('expiran_mes')">Expiran en 1 mes</a></li>
                                  </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center no-wrap">
                          <label class="col-form-label inline-block m-r-10">Mostrar</label>
                          <select class="form-control inline-block" (change)="Buscar()" [(ngModel)]="params.page_size" style="width:auto;">
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option>200</option>
                          </select>
                        </div>
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto text-center">
                          <div class="input-group width-100">
                            <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                            <div class="input-group-append cursor" (click)="Buscar()">
                              <span class="input-group-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center">
                          <a class="btn btn-default btn-primary btn-block" (click)="Nuevo()"><i class="fa fa-plus"></i> Nueva licencia</a>
                        </div>
                    </div>
                    <ul class="breadcrumb breadcrumb-top text-left">
                        <li><a [routerLink]="['/']">Inicio</a></li>
                        <li>Licencias</li>
                    </ul>
                </div>
                <!-- Table Styles Content -->
                <div class="table-responsive table-grid">
                    <!--
                    Available Table Classes:
                        'table'             - basic table
                        'table-bordered'    - table with full borders
                        'table-borderless'  - table with no borders
                        'table-striped'     - striped table
                        'table-condensed'   - table with smaller top and bottom cell padding
                        'table-hover'       - rows highlighted on mouse hover
                        'table-vcenter'     - middle align content vertically
                    -->
                    <table class="table table-striped table-bordered table-vcenter table-hover">
                        <thead>
                            <tr>
                              <!-- <th style="width:50px;" class="text-center"><label class="csscheckbox csscheckbox-primary"><input type="checkbox"><span></span></label></th> -->
                              <th order="clave">Tipo</th>
                              <th style="width:300px" order="clave">Clave</th>
                              <th style="width:130px" order="alta">Alta</th>
                              <th style="width:200px" order="email">Email</th>
                              <th order="cuota">Cuota</th>
                              <th order="fecha_expiracion">Fecha Expiraci&oacute;n</th>
                              <th style="width:50px">Editar</th>
                              <th style="width:50px">Borrar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of licencias"
                              class="cursor
                              {{(item.expira_1_mes ? 'bg-tr-expira-mes': '')}}
                              {{(item.expirada ? 'bg-tr-expirada': '')}}">
                              <!-- <td class="text-center"><label class="csscheckbox csscheckbox-primary"><input type="checkbox"><span></span></label></td> -->
                              <td class="" (click)="Editar(item)">
                                <div class="font-bold" [innerHTML]="item.tipo"></div>
                                <div class="text-primary" *ngIf="!item.enlace">{{item.dominio}}</div>
                                <div>
                                  <a class="text-primary" [href]="item.enlace" target="_blank" onclick="event.stopPropagation();"
                                  *ngIf="item.enlace">{{item.dominio}}</a>
                                </div>
                              </td>
                              <td class="font-bold text-primary font-12 breakword" (click)="Editar(item)">{{item.clave}}</td>
                              <td>{{item.alta_format}}</td>
                              <td class="breaktd">
                                <div *ngIf="item.email" class="no-wrap"><i class="fa fa-envelope"></i> {{item.email}}</div>
                                <div *ngIf="item.fecha_ultima_notificacion_format">Notificado el: {{item.fecha_ultima_notificacion_format}}</div>
                              </td>
                              <td class="breaktd"><span *ngIf="item.cuota" class="no-wrap font-bold font-18">{{item.cuota_format}}€ / {{item.cuota_tipo}}</span></td>
                              <td class="breaktd">
                                <div *ngIf="item.fecha_expiracion_format"><i class="fa fa-clock-o"></i> {{item.fecha_expiracion_format}}</div>
                              </td>
                              <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="Editar(item)"><i class="fa fa-pencil text-white"></i></a></td>
                              <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="Borrar(item)"><i class="fa fa-trash text-white"></i></a></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="dataTables_paginate paging_simple_numbers m-r-10">
                      <ul class="pagination justify-content-end">
                        <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}">
                          <a (click)="Paging('prev')" class="page-link">Anterior</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index > 1">
                          <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
                        </li>
                        <li class="paginate_button page-item active">
                          <a class="page-link cursor">{{params.page_index}}</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
                          <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
                        </li>
                        <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}">
                          <a (click)="Paging('next')" class="page-link">Siguiente</a>
                        </li>
                      </ul>
                    </div>
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>

<div class="modal fade" id="modal-crear-licencia" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title"><i class="icon-list"></i> Nueva licencia</h4>
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
        </div>
        <div class="modal-body">
            <div class="form-group row">
                <label class="col-lg-3 col-form-label">Tipo</label>
                <div class="col-lg-9">
                    <input type="url" class="form-control" [(ngModel)]="data.tipo" maxlength="50">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-3 col-form-label font-bold">Clave <i class="fa fa-key"></i> <span class="text-danger">*</span></label>
                <div class="col-lg-9">
                    <input id="txtclave" type="text" class="form-control" [(ngModel)]="data.clave" maxlength="50">
                    <div id="txtclavemsg" class="m-l-10 text-primary"></div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-3 col-form-label">Dominio</label>
                <div class="col-lg-9">
                    <input type="url" class="form-control" [(ngModel)]="data.dominio" maxlength="50" readonly>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-3 col-form-label">Email</label>
                <div class="col-lg-9">
                    <input type="email" class="form-control" [(ngModel)]="data.email" maxlength="50">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-3 col-form-label">Cuota</label>
                <div class="col-lg-5">
                    <input type="number" class="form-control text-right" [(ngModel)]="data.cuota" maxlength="20">
                </div>
                <div class="col-lg-4">
                    <select class="form-control" [(ngModel)]="data.cuota_tipo">
                      <option value="año" selected>Año</option>
                      <option value="mes" selected>Mes</option>
                      <option value="semestral" selected>Semestral</option>
                      <option value="trimestral" selected>Trimestral</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-3 col-form-label">Fecha expiración</label>
                <div class="col-lg-9">
                  <div class="input-group date" id="fechaexpiracion">
                    <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
                    <input type="text" class="form-control">
                  </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-3 col-form-label">Notas</label>
                <div class="col-lg-9">
                  <textarea class="form-control" [(ngModel)]="data.notas" rows="10" placeholder="Notas sobre el cliente"></textarea>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button id="btnGuardarModal" type="button" class="btn btn-primary" (click)="GuardarModal()">Guardar</button>
        </div>
        </div>
    </div>
</div>

<svg style="display:none;">
  <style>
    .bg-tr-expira-mes {
      background-color: #fadea1 !important;
    }
    .bg-tr-expira-mes * {
      color: #8e4e30;
    }
    .bg-tr-expirada {
      background-color: #EEE !important;
    }
    .bg-tr-expirada * {
      color: #949494;
    }
  </style>
</svg>