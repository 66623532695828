import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CRMCampanasComponent } from './backend/crm/campanas/campanas.component';
import { CRMEditCampanaComponent } from './backend/crm/campanas/edit-campana/edit-campana.component';
import { CRMClientesComponent } from './backend/crm/clientes/clientes.component';
import { CRMEditClienteComponent } from './backend/crm/clientes/edit-cliente/edit-cliente.component';
import { CRMConfigComponent } from './backend/crm/config/config.component';
import { CRMMensajesComponent } from './backend/crm/mensajes/mensajes.component';
import { CRMEditPlantillaComponent } from './backend/crm/plantillas/edit-plantilla/edit-plantilla.component';
import { CRMPlantillasComponent } from './backend/crm/plantillas/plantillas.component';
import { DashboardComponent } from './backend/dashboard/dashboard.component';
import { EmailBuilderComponent } from './backend/email-builder/email-builder.component';
import { LicenciasComponent } from './backend/licencias/licencias.component';
import { LoginComponent } from './backend/login/login.component';
import { SeguimientoServidoresComponent } from './backend/seguimiento-servidores/seguimiento-servidores.component';
import { EditUsuarioComponent } from './backend/usuarios/edit-usuario/edit-usuario.component';
import { UsuariosComponent } from './backend/usuarios/usuarios.component';
import { WhatsappWebComponent } from './frontend/whatsapp-web/whatsapp-web.component';
import { ConfigAppComponent } from './backend/config-app/config-app.component';
import { GeneradorLogsAccesoComponent } from './backend/generador-logs-acceso/generador-logs-acceso.component';
import { GeneradorDNSComponent } from './backend/generador-dns/generador-dns.component';

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'config-app', component: ConfigAppComponent},
  {path: 'usuarios', component: UsuariosComponent},
  {path: 'licencias', component: LicenciasComponent},
  {path: 'licencias/add', component: LicenciasComponent},
  {path: 'email-builder', component: EmailBuilderComponent},
  {path: 'crm-config', component: CRMConfigComponent},
  {path: 'crm-clientes', component: CRMClientesComponent},
  {path: 'crm-clientes/add', component: CRMEditClienteComponent},
  {path: 'crm-clientes/edit', component: CRMEditClienteComponent},
  {path: 'crm-campanas', component: CRMCampanasComponent},
  {path: 'crm-campanas/add', component: CRMEditCampanaComponent},
  {path: 'crm-campanas/edit', component: CRMEditCampanaComponent},
  {path: 'crm-campanas/clone', component: CRMEditCampanaComponent},
  {path: 'crm-plantillas', component: CRMPlantillasComponent},
  {path: 'crm-plantillas/add', component: CRMEditPlantillaComponent},
  {path: 'crm-plantillas/edit', component: CRMEditPlantillaComponent},
  {path: 'crm-plantillas/clone', component: CRMEditPlantillaComponent},
  {path: 'crm-mensajes', component: CRMMensajesComponent},
  {path: 'seguimiento-servidores', component: SeguimientoServidoresComponent},
  {path: 'usuarios', component: UsuariosComponent},
  {path: 'usuarios/add', component: EditUsuarioComponent},
  {path: 'usuarios/edit', component: EditUsuarioComponent},
  {path: 'generador-logs-acceso', component: GeneradorLogsAccesoComponent},
  {path: 'generador-dns', component: GeneradorDNSComponent},
  //FrontEnd
  {path: 'whatsapp-web', component: WhatsappWebComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
